import { uiController } from '@/controllers/uiController'
import { buyVipService } from '@/services/buyVipService'
import { ActivityHelperTypes } from './types'
import { activityConfigInfoList } from './consts'
import { toastController } from '@/controllers/toastController'
import { commonUtils } from '../commonUtils'
import { userService } from '@/services/userService'
// import { makeObservable } from 'mobx'

class ActivityHelper {
  constructor() {
    for (const activity of activityConfigInfoList) {
      // buyVipService.setActivityConfigInfo({
      //   hashId: activity.hashId,
      //   configInfo: JSON.stringify(activity.configInfo),
      // })
    }
  }
  public async handleActivityBtnClick(
    activity: ServerDataTypes.ActivityData,
    cb?: (bool: boolean) => void
  ) {
    const { activityConfig } = this.getCurActivityConfigInfo(activity)
    const { planData, couponData, activityData } = activity.data[0]
    switch (activityConfig?.actionType) {
      case 'pay':
        if (activity.showType !== 'pay_page_top_banner') {
          commonUtils.sendExtensionMessage(
            'handleActivityBtnClick',
            {
              activity,
            },
            (res: any) => {
              if (res.success) {
                if (res.errMsg) {
                  toastController.warning(res.errMsg)
                }
              }
            },
            'sidePanel'
          )
        } else {
          uiController.payQrDialogProps = {
            open: true,
            plan: planData,
            planTitle: activityData.title,
            planDesc: activityConfig.remark,
            source: activity.showType,
          }
        }
        break
      case 'coupon':
        await buyVipService.receiveCoupon(couponData!.hashId, (bool) => {
          cb?.(bool)
          if (bool) {
            toastController.success('优惠券领取成功')
            if (activity.showType !== 'pay_page_top_banner') {
              commonUtils.sendExtensionMessage('handleActivityBtnClick', {
                activity,
              })
            }
          }
        })
        break
      case 'link':
        window.open(activityConfig!.linkUrl!, '_blank')
        break
      case 'buyVipModal':
        commonUtils.sendExtensionMessage(
          'handleActivityBtnClick',
          {
            activity,
          },
          (res: any) => {
            if (res.success) {
              if (res.errMsg) {
                toastController.warning(res.errMsg)
              }
            }
          },
          'sidePanel'
        )
        break
      default:
        break
    }
    buyVipService.triggerUpdatePlanList = !buyVipService.triggerUpdatePlanList
  }

  public getCurActivityConfigInfo(activity: ServerDataTypes.ActivityData): {
    activityConfig?: ActivityHelperTypes.ActivityConfig
    expireTime?: number
    lessThen72Hours?: boolean
  } {
    const {
      activityData: { configInfo, expireTime: activityExpireTime },
      couponData,
      planData,
    } = activity.data[0]
    const activityConfigList = JSON.parse(configInfo) as ActivityHelperTypes.ActivityConfig[]
    let position = activity.showType
    if (!activityConfigList.some((a) => a.position === activity.showType)) {
      position = 'default' as any
    }

    let expireTime: number | undefined
    let lessThen72Hours: boolean | undefined
    const activityConfig = activityConfigList.find((a) => {
      if (a.position === position) {
        if (a.couponHashId) {
          return a.couponHashId === couponData?.hashId
        }
        if (a.planId) {
          return a.planId === planData?.id
        }
        if (a.countdown) {
          if (a.countdown.countdownKey === 'vipExpiredTime') {
            expireTime = userService.vipExpiredTime * 1000
          } else {
            expireTime = activityExpireTime * 1000
          }
          lessThen72Hours =
            a.countdown.format === 'd' && expireTime - Date.now() <= 72 * 60 * 60 * 1000
        }
        return true
      }
      return false
    })
    return { activityConfig, expireTime, lessThen72Hours }
  }
}

export const activityHelper = new ActivityHelper()
