import { post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export namespace sidePanel {
  // 划词翻译接口
  export function scribeTranslate(
    params: PostDataTypes.ScribeTranslateOptions,
  ): Promise<ApiBaseResult & { data: ServerDataTypes.ScribeTranslateResult }> {
    return post('/scribe/translate_v2', {}, { ...params, isDecr: true })
  }
}
