import { ActivityHelperTypes } from './types'

export const activityConfigInfoList: ActivityHelperTypes.Activity[] = [
  // 未付费且有下单意愿用户提供优惠券促销
  {
    hashId: 'sj110bg6v',
    configInfo: [
      {
        closable: true,
        btnOptions: {
          btnText: '领取并使用',
          x: 135,
          y: 265,
        },
        actionType: 'coupon',
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/coupon-retain-modal.png',
        },
        position: 'pay_modal_cancel',
      },
    ],
  },
  // 取消连续包月用户过期前促销
  {
    hashId: 'sj60693fs',
    configInfo: [
      // 连续包月的气泡漏出配置项
      {
        closable: true,
        closeBtnOptions: {
          y: 52,
          right: 36,
        },
        btnOptions: {
          bottom: 81,
          right: 122,
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-cancel-subacription-btn-hover.png',
        },
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-cancel-subacription-10.png',
        },
        countdown: {
          x: 79,
          y: 69,
          countdownKey: 'vipExpiredTime',
          label: '翻译功能',
          afterLabel: '后过期',
        },
        // couponHashId: 'vpciyuews',
        position: 'bottom_right_bubble',
        actionType: 'coupon',
      },
      // 连续包月、连续包季popup顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-cancel-subacription.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-cancel-subacription-hour.png',
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-cancel-subacription-btn-hover.png',
          bottom: 10,
          width: 51,
          right: 10,
        },
        countdown: {
          format: 'd',
          x: 108,
          y: 12,
          countdownKey: 'vipExpiredTime',
          hourX: 109,
          hourY: 12,
          h: 18,
        },
        position: 'popup_top_banner',
        actionType: 'coupon',
      },
      // 连续包月、连续包季付费页面顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-cancel-subacription.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-cancel-subacription-hour.png',
        },
        btnOptions: {
          width: 133,
          y: 44,
          right: 35,
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-cancel-subacription-btn-hover.png',
        },
        countdown: {
          format: 'd',
          x: 246,
          y: 26,
          countdownKey: 'vipExpiredTime',
          hourX: 245,
          hourY: 26,
          h: 34,
        },
        position: 'pay_page_top_banner',
        actionType: 'coupon',
      },
      // 连续包月、连续包季付费弹窗顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-cancel-subacription.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-cancel-subacription-hour.png',
        },
        btnOptions: {
          width: 85,
          right: 20,
          y: 16,
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-cancel-subacription-btn-hover.png',
        },
        countdown: {
          format: 'd',
          x: 160,
          y: 22,
          countdownKey: 'vipExpiredTime',
          hourX: 160,
          hourY: 22,
          h: 24,
        },
        position: 'pay_modal_top_banner',
        actionType: 'coupon',
      },
      // 连续包月、连续包季侧边栏顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription.png',
          bgUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription-bg.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription-hour.png',
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription-btn-hover.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription-btn.png',
          bottom: 11,
          width: 56,
          right: 16,
        },
        countdown: {
          format: 'd',
          x: 110,
          y: 9,
          countdownKey: 'vipExpiredTime',
          hourX: 110,
          hourY: 10,
          h: 18,
        },
        position: 'sidebar_top_banner',
        actionType: 'coupon',
      },
    ],
  },
  // 连续包季用户过期前促销
  {
    hashId: 'sdfs98a3sv',
    configInfo: [
      // 连续包季的气泡漏出配置
      {
        closable: true,
        closeBtnOptions: {
          y: 52,
          right: 36,
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-cancel-subacription-btn-hover.png',
          bottom: 81,
          right: 122,
        },
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-cancel-subacription-5.png',
        },
        countdown: {
          x: 79,
          y: 69,
          countdownKey: 'vipExpiredTime',
          label: '翻译功能',
          afterLabel: '后过期',
        },
        // couponHashId: 'eonnxiojp',
        position: 'bottom_right_bubble',
        actionType: 'coupon',
      },
      // 连续包月、连续包季popup顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-cancel-subacription.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-cancel-subacription-hour.png',
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-cancel-subacription-btn-hover.png',
          bottom: 10,
          width: 51,
          right: 10,
        },
        countdown: {
          format: 'd',
          x: 108,
          y: 12,
          countdownKey: 'vipExpiredTime',
          hourX: 109,
          hourY: 12,
          h: 18,
        },
        position: 'popup_top_banner',
        actionType: 'coupon',
      },
      // 连续包月、连续包季付费页面顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-cancel-subacription.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-cancel-subacription-hour.png',
        },
        btnOptions: {
          width: 133,
          y: 44,
          right: 35,
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-cancel-subacription-btn-hover.png',
        },
        countdown: {
          format: 'd',
          x: 246,
          y: 26,
          countdownKey: 'vipExpiredTime',
          hourX: 245,
          hourY: 26,
          h: 34,
        },
        position: 'pay_page_top_banner',
        actionType: 'coupon',
      },
      // 连续包月、连续包季付费弹窗顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-cancel-subacription.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-cancel-subacription-hour.png',
        },
        btnOptions: {
          width: 85,
          right: 20,
          y: 16,
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-cancel-subacription-btn-hover.png',
        },
        countdown: {
          format: 'd',
          x: 160,
          y: 22,
          countdownKey: 'vipExpiredTime',
          hourX: 160,
          hourY: 22,
          h: 24,
        },
        position: 'pay_modal_top_banner',
        actionType: 'coupon',
      },
      // 连续包月、连续包季侧边栏顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription.png',
          bgUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription-bg.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription-hour.png',
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription-btn-hover.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-cancel-subacription-btn.png',
          bottom: 11,
          width: 56,
          right: 16,
        },
        countdown: {
          format: 'd',
          x: 110,
          y: 9,
          countdownKey: 'vipExpiredTime',
          hourX: 110,
          hourY: 10,
          h: 18,
        },
        position: 'sidebar_top_banner',
        actionType: 'coupon',
      },
    ],
  },
  // 触发功能限额，1元买7天vip
  {
    hashId: 'sj606gtso',
    configInfo: [
      {
        btnOptions: {
          x: 111,
          y: 265,
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/banner-activity-trial-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/banner-activity-trial-btn-hover.png',
        },
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/banner-activity-trial.png',
          smallUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/banner-activity-trial-small.png',
          smallW: 283,
        },
        position: 'default',
        actionType: 'pay',
        remark: '*7天到期后将以8元/月进行续费，可随时取消',
      },
    ],
  },
  // 补差价升级
  {
    hashId: 'sj607873d',
    configInfo: [
      {
        closable: true,
        closeBtnOptions: {
          bottom: 300,
          right: 33,
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-upgrade-btn-1.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-upgrade-btn-hover.png',
          bottom: 92,
          right: 115,
        },
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/banner-diff-price-50.png',
        },
        position: 'bottom_right_bubble',
        actionType: 'pay',
      },
    ],
  },
  // 免费会员促销，多送1个月
  {
    hashId: 'sj60722tw',
    configInfo: [
      // 气泡漏出
      {
        closable: true,
        closeBtnOptions: {
          bottom: 321,
          right: 30,
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-cancel-subacription-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-cancel-subacription-btn-hover.png',
          bottom: 92,
          right: 109,
        },
        countdown: {
          x: 79,
          y: 94,
          countdownKey: 'vipExpiredTime',
          label: '翻译功能',
          afterLabel: '后过期',
        },
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/banner-1-month.png',
        },
        position: 'bottom_right_bubble',
        actionType: 'pay',
      },
      // popup顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-1-month.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-banner-1-month-hour.png',
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-banner-1-month-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-banner-1-month-btn-hover.png',
          bottom: 10,
          right: 10,
          width: 51,
        },
        countdown: {
          format: 'd',
          x: 108,
          y: 12,
          countdownKey: 'vipExpiredTime',
          hourX: 109,
          hourY: 12,
          h: 18,
        },
        position: 'popup_top_banner',
        actionType: 'pay',
      },
      // 侧边栏顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-1-month.png',
          bgUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-1-month-bg.png',
          hourUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-1-month-hour.png',
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-1-month-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-1-month-btn-hover.png',
          bottom: 11,
          width: 56,
          right: 16,
        },
        countdown: {
          format: 'd',
          x: 110,
          y: 9,
          countdownKey: 'vipExpiredTime',
          hourX: 110,
          hourY: 10,
          h: 18,
        },
        position: 'sidebar_top_banner',
        actionType: 'pay',
      },
    ],
  },
  // 限时促销48
  {
    hashId: 'sj606ngvi',
    configInfo: [
      // popup顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/banner-popup-48-pre-year.png',
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-banner-new-user-discount-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-banner-new-user-discount-btn-hover.png',
          right: 11,
          bottom: 10,
          width: 73,
        },
        countdown: {
          format: 'h-m-s',
          x: 166,
          y: 12,
          countdownKey: 'activityExpiredTime',
        },
        position: 'popup_top_banner',
        actionType: 'pay',
      },
      // 气泡漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-48-pre-year.png',
        },
        closable: true,
        closeBtnOptions: {
          right: 34,
          y: 82,
        },
        btnOptions: {
          right: 108,
          y: 294,
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-48-pre-year-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/bubble-48-pre-yea-btn-hover.png',
        },

        countdown: {
          format: 'h-m-s',
          x: 78,
          y: 95,
          label: '福利倒计时：',
        },
        position: 'bottom_right_bubble',
        actionType: 'pay',
      },
      // 支付弹窗
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/banner-pay-modal-48-pre-year.png',
        },

        countdown: {
          format: 'h-m-s',
          x: 243,
          y: 18,
        },
        position: 'pay_modal_top_banner',
        actionType: 'pay',
      },
      // 侧边栏顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-48-pre-year.png',
          bgUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-48-pre-year-bg.png',
        },
        btnOptions: {
          btnImg:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-48-pre-year-btn.png',
          btnImgHover:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-48-pre-year-btn-hover.png',
          right: 11,
          bottom: 10,
          width: 73,
        },
        countdown: {
          format: 'h-m-s',
          x: 178,
          y: 13,
          countdownKey: 'activityExpiredTime',
        },
        position: 'sidebar_top_banner',
        actionType: 'pay',
      },
      // 付费页面顶部banner漏出
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-banner-48-pre-year-bg.png',
        },

        countdown: {
          format: 'h-m-s',
          x: 373,
          y: 69,
        },
        position: 'pay_page_top_banner',
        actionType: 'pay',
      },
    ],
  },
  // 新用户福利套餐
  {
    hashId: 'sd60csa3kf',
    configInfo: [
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/popup-banner-new-user-discount.png',
        },
        position: 'popup_top_banner',
        actionType: 'buyVipModal',
        allowClick: true,
      },
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-modal-banner-new-user-discount.png',
        },
        activePlan: {
          icon: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/new-user-discount-plan-icon.png',
          planId: 'ss0dmaas',
        },
        position: 'pay_modal_top_banner',
      },
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/pay-page-banner-new-user-discount.png',
        },
        activePlan: {
          icon: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/new-user-discount-plan-icon.png',
          planId: 'ss0dmaas',
        },
        position: 'pay_page_top_banner',
      },
      {
        imageOptions: {
          url: 'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-new-user-discount.png',
          bgUrl:
            'https://assets.weibanzhushou.com/web/pt-lenovo/activity/sidebar-banner-new-user-discount-bg.png',
        },
        actionType: 'buyVipModal',
        allowClick: true,
        position: 'sidebar_top_banner',
      },
    ],
  },
]
