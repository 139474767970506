import { activityHelper } from '@/helpers/activityHelper/activityHelper'
import { ActivityHelperTypes } from '@/helpers/activityHelper/types'
import { api } from '@/helpers/api'
import { ptProxyHelper } from '@/helpers/ptProxyHelper'
import { buyVipService } from '@/services/buyVipService'
import { userService } from '@/services/userService'
import { Button } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import style from './BannerBox.module.less'

/** BannerBox 组件的 Props */
export type BannerBoxProps = {
  activity?: ServerDataTypes.ActivityData
  type: 'pay_page_top_banner' | 'sidebar_top_banner'
  styleCSS?: React.CSSProperties
  onRender?: (activity: ServerDataTypes.ActivityData) => void
  onActivityClick?: (activity: ServerDataTypes.ActivityData) => void
}

export const BannerBox = observer((props: BannerBoxProps) => {
  const [visible, setVisible] = useState(false)
  const [activityConfig, setActivityConfig] = useState<ActivityHelperTypes.ActivityConfig | null>(
    null
  )
  const [activity, setActivity] = useState<ServerDataTypes.ActivityData | null>(null)
  const [hasCountdown, setHasCountdown] = useState(false)
  const [expireTime, setExpireTime] = useState(0)
  const [lessThan72Hours, setLessThan72Hours] = useState(false)
  const initData = async () => {
    let matchedActivity: ServerDataTypes.ActivityData | undefined = props.activity
    if (!matchedActivity) {
      if (props.type !== 'pay_page_top_banner') {
        matchedActivity = (await buyVipService.checkHasMatchedActivity(props.type)) || undefined
      }
      if (!matchedActivity) {
        setVisible(false)
        return
      }
    }
    const { activityConfig, expireTime, lessThen72Hours } = activityHelper.getCurActivityConfigInfo(
      matchedActivity!
    )
    console.log('activityConfig', activityConfig, lessThan72Hours)
    if (!activityConfig) {
      return
    }
    setExpireTime(expireTime || 0)
    setLessThan72Hours(lessThen72Hours || false)
    setActivity(matchedActivity!)
    //  这里命中的气泡活动了
    setVisible(true)
    setActivityConfig(activityConfig)
    if (expireTime) {
      setHasCountdown(true)
    } else {
      setHasCountdown(false)
    }
    buyVipService.saveActivityShow({
      activityHashId: matchedActivity!.data[0].activityData.hashId,
      showType: matchedActivity!.showType,
      couponHashId: matchedActivity!.data[0].couponData?.hashId,
      planId: matchedActivity!.data[0].planData?.id,
    })
  }
  useEffect(() => {
    initData()
    ptProxyHelper.listenExtensionEvent('updateActivityStatus', () => {
      initData()
    })
  }, [])

  useEffect(() => {
    if (visible && activity) {
      props.onRender?.(activity)
    }
  }, [visible, activity])

  if (!visible || !activity) {
    return null
  }
  return (
    <div
      className={classNames(style.bannerBox, {
        [style.allowClick]: activityConfig?.allowClick,
        [style.sidebarBanner]: activity.showType === 'sidebar_top_banner',
      })}
      style={props.styleCSS}
      onClick={() => {
        if (activityConfig?.allowClick) {
          activityHelper.handleActivityBtnClick(activity)
          props.onActivityClick?.(activity)
        }
      }}
    >
      <div className={style.bannerBoxInner}>
        {!!activityConfig?.imageOptions?.bgUrl && (
          <img className={style.bannerBoxBg} src={activityConfig?.imageOptions?.bgUrl} alt='' />
        )}
        <img
          className={style.bannerBoxImg}
          src={
            lessThan72Hours
              ? activityConfig?.imageOptions?.hourUrl
              : activityConfig?.imageOptions?.url
          }
          alt=''
        />
        {hasCountdown && (
          <CountdownBox
            hourX={activityConfig?.countdown?.hourX}
            hourY={activityConfig?.countdown?.hourY}
            h={activityConfig?.countdown?.h}
            x={activityConfig?.countdown?.x}
            y={activityConfig?.countdown?.y}
            expireTime={expireTime}
            format={activityConfig?.countdown?.format}
            lessThan72HoursHandler={() => {
              setLessThan72Hours(true)
            }}
          />
        )}
        {!!activityConfig?.btnOptions?.btnText && (
          <Button
            style={{
              bottom: activityConfig?.btnOptions?.bottom,
              right: activityConfig?.btnOptions?.right,
              left: activityConfig?.btnOptions?.x,
              top: activityConfig?.btnOptions?.y,
              width: activityConfig?.btnOptions?.width,
            }}
            className={style.bannerBoxBtn}
            onClick={() => {
              activityHelper.handleActivityBtnClick(activity)
              props.onActivityClick?.(activity)
            }}
          >
            {activityConfig?.btnOptions?.btnText}
          </Button>
        )}
        {!!activityConfig?.btnOptions?.btnImg && (
          <span
            className={style.bannerBoxBtnImg}
            style={{
              bottom: activityConfig?.btnOptions?.bottom,
              right: activityConfig?.btnOptions?.right,
              left: activityConfig?.btnOptions?.x,
              top: activityConfig?.btnOptions?.y,
              width: activityConfig?.btnOptions?.width,
            }}
            onClick={() => {
              activityHelper.handleActivityBtnClick(activity)
              props.onActivityClick?.(activity)
            }}
          >
            <img
              className={style.bannerBoxBtnImgNormal}
              src={activityConfig?.btnOptions?.btnImg}
              alt=''
            />
            <img
              className={style.bannerBoxBtnImgHover}
              src={activityConfig?.btnOptions?.btnImgHover}
              alt=''
            />
          </span>
        )}
      </div>
    </div>
  )
})
const NUM: string[] = [
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/0.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/1.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/2.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/3.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/4.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/5.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/6.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/7.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/8.png',
  'https://assets.weibanzhushou.com/web/pt-lenovo/activity/num/9.png',
]
const CountdownBox = observer(
  ({
    x,
    y,
    label,
    afterLabel,
    expireTime,
    format = 'd-h-m',
    timeEnd,
    from = 'banner',
    withPayModal,
    lessThan72HoursHandler,
    hourX,
    hourY,
    h,
  }: {
    x?: number
    y?: number
    label?: string
    afterLabel?: string
    expireTime: number
    format?: 'd-h-m' | 'h-m-s' | 'd'
    timeEnd?: () => void
    from?: 'banner' | 'bubble'
    withPayModal?: boolean
    lessThan72HoursHandler?: () => void
    hourX?: number
    hourY?: number
    h?: number
  }) => {
    const [timeLeft, setTimeLeft] = useState(expireTime - Date.now())
    useEffect(() => {
      const timer = setInterval(() => {
        const diff = expireTime - Date.now()
        if (diff <= 0) {
          clearInterval(timer)
          timeEnd?.()
          return
        }
        setTimeLeft(diff)
      }, 1000)

      return () => clearInterval(timer)
    }, [expireTime])
    useEffect(() => {
      if (format === 'd' && timeLeft < 72 * 60 * 60 * 1000) {
        lessThan72HoursHandler?.()
      }
    }, [timeLeft, format])

    const parseTime = (t: number) => {
      if (format === 'd') {
        return t
      }
      return t < 10 ? `0${t}` : t + ''
    }
    const isLessThan72Hours = timeLeft < 72 * 60 * 60 * 1000
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
    let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000)
    if (isLessThan72Hours) {
      hours = days * 24 + hours
    }
    if (format === 'd') {
      return (
        <div
          className={classNames(style.countdownBox, style.onlyD, {
            [style.fromBanner]: from === 'banner',
            [style.fromBubble]: from === 'bubble',
            [style.withPayModal]: withPayModal,
          })}
          style={{
            left: isLessThan72Hours ? hourX : x,
            top: isLessThan72Hours ? hourY : y,
          }}
        >
          <span>
            {isLessThan72Hours && (
              <>
                {Math.floor(hours / 10) > 0 && (
                  <img style={{ height: `${h}px` }} src={NUM[Math.floor(hours / 10)]} />
                )}
                <img style={{ height: `${h}px` }} src={NUM[hours % 10]} />
              </>
            )}
            {!isLessThan72Hours && <img style={{ height: `${h}px` }} src={NUM[days]} />}
          </span>
        </div>
      )
    }
    return (
      <div
        className={classNames(style.countdownBox, {
          [style.fromBanner]: from === 'banner',
          [style.fromBubble]: from === 'bubble',
          [style.withPayModal]: withPayModal,
        })}
        style={{
          left: x,
          top: y,
        }}
      >
        {label}
        {format === 'd-h-m' && !isLessThan72Hours && (
          <>
            <span>{parseTime(days)}</span> {from === 'banner' ? ':' : '天'}
          </>
        )}
        <span>{parseTime(hours)}</span>
        {from === 'banner' ? ':' : '时'}
        <span>{parseTime(minutes)}</span>
        {from === 'banner' ? ':' : '分'}
        {(format === 'h-m-s' || isLessThan72Hours) && (
          <>
            <span>{parseTime(seconds)}</span>
            {from === 'banner' ? '' : '秒'}
          </>
        )}
        {afterLabel}
      </div>
    )
  }
)
