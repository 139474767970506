import { Z_INDEX_MAX } from '@/helpers/commonConsts'
import { ComponentFactory } from '@/mobxTools'
import { Modal } from 'antd'
import classNames from 'classnames'
import style from './PaySuccessEducationDiaLog.module.less'
import { PaySuccessEducationDiaLogVm } from './PaySuccessEducationDiaLogVm'
import { commonUtils } from '@/helpers/commonUtils'
import { message } from 'antd';



/** PaySuccessDialog 组件的 Props */
export type PaySuccessEducationDiaLogProps = {
  open: boolean
  btnText?: string
  onClose?: () => void
}


/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const PaySuccessEducationDiaLog = ComponentFactory.createComponent(
  'PaySuccessEducationDiaLog',
  PaySuccessEducationDiaLogVm,
  (vm) => {
    const {  open, btnText, onClose } = vm.props
    if (!open) {
      return null
    }

    const onCopy = () => {
        commonUtils.copyTextToClipboard(window.location.href).then(() =>{
            message.open({
                type: 'success',
                content: '复制成功',
                className: 'custom-class',
                style: {
                marginTop: '20px',
              },
            });
        })
    
    }

    return (
      <Modal
        open={open}
        zIndex={Z_INDEX_MAX}
        maskClosable={false}
        centered
        width={516}
        footer={false}
        getContainer={false}
        wrapClassName={style.paySuccessDialog}
        onCancel={() => {
          if (onClose) {
            onClose()
          }
        }}
      >
        <div className={classNames(style.paySuccessDialogContent)}>
          <p className={classNames(style.title)}>支付成功</p>
          <img
            className={classNames(style.successIcon)}
            src='https://assets.weibanzhushou.com/web/pt-web/pricing-page_success.a091509e2a4b.png'
          />
          <div className={classNames(style.tipsTitle)}>恭喜您购买成功</div>
          <div className={classNames(style.tipsContent)}>若您有同学或朋友需要，可点击「复制链接」去分享～</div>
          <div className={classNames(style.btnBox, style.flexRow)}>

            <div className={classNames(style.giftReceiveBtn)} onClick={onCopy}>
            复制链接
            </div>
  
            <div
              className={classNames(style.btn)}
              onClick={() => {
                if (onClose) {
                  onClose()
                }
              }}
            >
              {btnText || '关闭'}
            </div>
          </div>
          <p className={classNames(style.desc)}>
            <img
              className={classNames(style.icon)}
              src='https://assets.weibanzhushou.com/web/pt-web/login-page_icon__select-gray.0478c24d27c8.png'
            />
            可联系客服开发票
          </p>
        </div>
      </Modal>
    )
  }
)
