import { ExchangeCodeDialogProps } from '@/components/ExchangeCodeDialog'
import { PaySuccessEducationDiaLogProps } from '@/components/PaySuccessEducationDiaLog'
import { PaySuccessDialogProps } from '@/components/PaySuccessDialog'
import { PdfUpgradeModalProps } from '@/components/PdfUpgradeModal'
import { ModalProps } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import { makeObservable, observable } from 'mobx'
import { userService } from '@/services/userService'
import { PayQrDialogProps } from '@/components/PayQrDialog'
import { CouponModalProps } from '@/components/CouponModal'

interface CommonModalProps extends ModalProps {
  content?: string
}
class UiController {
  @observable message!: MessageInstance
  @observable commonModalProps: CommonModalProps = { open: false }
  @observable paySuccessDialogProps: PaySuccessDialogProps = {
    open: false,
  }

  @observable paySuccessEducationDialogProps: PaySuccessEducationDiaLogProps = {
    open: false,
  }

  @observable exchangeDialogProps: ExchangeCodeDialogProps = {
    open: false,
  }
  @observable pdfUploadTimeLimitedProps: PdfUpgradeModalProps = {
    open: false,
  }
  @observable pdfChatTimeLimited = false
  @observable pdfUploadPageLimitedProps: PdfUpgradeModalProps = {
    open: false,
  }

  @observable loginModalProps: {
    open: boolean
    handleLoginSuccess?: () => void
  } = {
    open: false,
  }

  @observable operationRecordModalVisible = false

  @observable showDiscountModal = false
  @observable payQrDialogProps: PayQrDialogProps = {
    open: false,
  }
  @observable discountEndTime: number | undefined = undefined
  @observable showPhoneNumberModal = false
  @observable couponDialogProps: CouponModalProps = { open: false }

  public init() {
    makeObservable(this)
  }

  public async openDiscountOrClose() {
    this.closeLoginModal()
  }

  public async loginSuccess() {
    const userInfo = userService.userData
    await userService.updateUserInfo()

    // 注册时间
    const registerTime = userInfo!.createdAt * 1000
    // 当前时间
    const currentTime = Date.now()
    if (currentTime - registerTime < 60 * 1000 && !userInfo?.phoneNumber) {
      // 刚注册的用户，填写手机号
      this.showPhoneNumberModal = true
    } else {
      this.openDiscountOrClose()
    }
  }

  public closeLoginModal() {
    uiController.loginModalProps.handleLoginSuccess?.()
    uiController.loginModalProps = {
      open: false,
    }
  }
}

export const uiController = new UiController()
