import { uiController } from '@/controllers/uiController'
import { Modal } from 'antd'
import { observer } from 'mobx-react'
import { FC } from 'react'
import ExchangeCodeDialog from '../ExchangeCodeDialog/ExchangeCodeDialog'
import { PaySuccessDialog } from '../PaySuccessDialog'
import { PaySuccessEducationDiaLog } from '../PaySuccessEducationDiaLog'
import style from './CommonDialogLayout.module.less'
import { userService } from '@/services/userService'
import OperationRecordModal from '../OperationRecordModal/OperationRecordModal'
import LoginModal from '../LoginModal/LoginModal'
import { PayQrDialog } from '../PayQrDialog'
import { RegisterCheckPhoneNumber } from '../RegisterCheckPhoneNumber/RegisterCheckPhoneNumber'
import { CouponModal } from '../CouponModal'

interface CommonDialogLayoutProps {}

const CommonDialogLayout: FC<CommonDialogLayoutProps> = observer(
  (props: CommonDialogLayoutProps) => {
    return (
      <div>
        {/* 支付成功弹窗 */}
        <PaySuccessDialog
          onClose={() => {
            uiController.paySuccessDialogProps = { open: false }
            userService.invokePaySuccessfulListener()
          }}
          {...uiController.paySuccessDialogProps}
        ></PaySuccessDialog>
        {/* 教育优惠支付成功弹窗 */}
        <PaySuccessEducationDiaLog
          {...uiController.paySuccessEducationDialogProps}
          onClose={() => {
            uiController.paySuccessEducationDialogProps = { open: false }
          }}
        ></PaySuccessEducationDiaLog>
        {/* 通用提示弹窗 */}
        <Modal className={style.commonModal} centered {...uiController.commonModalProps}>
          {uiController.commonModalProps.content}
        </Modal>

        {uiController.exchangeDialogProps.open && (
          <ExchangeCodeDialog
            {...uiController.exchangeDialogProps}
            onClose={() => {
              uiController.exchangeDialogProps = { open: false }
            }}
          ></ExchangeCodeDialog>
        )}

        {/* 操作记录弹窗 */}
        <OperationRecordModal
          open={uiController.operationRecordModalVisible}
          onCancel={() => {
            uiController.operationRecordModalVisible = false
          }}
        ></OperationRecordModal>

        {/* 登录弹窗 */}
        {uiController.loginModalProps.open && (
          <LoginModal
            open={uiController.loginModalProps.open}
            onCancelClick={() => {
              uiController.loginModalProps = { open: false }
            }}
            onLoginSuccess={() => {
              uiController.loginSuccess()
            }}
          />
        )}

        {/* 填写手机号码弹窗 */}
        {uiController.showPhoneNumberModal && (
          <Modal
            open={uiController.showPhoneNumberModal}
            width={528}
            footer={null}
            centered={true}
            title={null}
            maskClosable={false}
            styles={{
              content: {
                borderRadius: '28px',
                padding: '32px 76px 24px',
                background: '#fff',
              },
            }}
            onCancel={() => {
              uiController.showPhoneNumberModal = false
              uiController.openDiscountOrClose()
            }}
          >
            <RegisterCheckPhoneNumber
              onSuccess={() => {
                uiController.showPhoneNumberModal = false
                uiController.openDiscountOrClose()
              }}
              showFooterLink={true}
            ></RegisterCheckPhoneNumber>
          </Modal>
        )}

        {/* 支付二维码弹窗 */}
        <PayQrDialog
          {...uiController.payQrDialogProps}
          paySuccess={() => {
            // 支付成功 关闭弹窗
            uiController.payQrDialogProps = { open: false }
            // 支付成功弹窗
            uiController.paySuccessDialogProps = {
              open: true,
              onClose: () => {
                uiController.paySuccessDialogProps = { open: false }
                uiController.closeLoginModal()
              },
            }
          }}
          onClose={() => {
            uiController.payQrDialogProps = { open: false }
          }}
        ></PayQrDialog>

        <CouponModal
          onClose={() => {
            uiController.couponDialogProps = { open: false }
          }}
          {...uiController.couponDialogProps}
        />
      </div>
    )
  }
)

export default CommonDialogLayout
