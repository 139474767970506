import { ee } from '@/services/eventEmitter/eventEmitter'
import { userService } from '@/services/userService'
import { Spin } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { OnlineFooter } from '../OnlineFooter/OnlineFooter'
import PageHeader from '../PageHeader/PageHeader'
import style from './GlobalLayout.module.less'

interface GlobalLayoutProps {
  pageHeaderVisible?: boolean
  rightBoxVisible?: boolean
  children: React.ReactNode
  hideFooter?: boolean
  useNewFooter?: boolean
  showFooterLinks?: boolean
}

const GlobalLayout: FC<GlobalLayoutProps> = observer((props: GlobalLayoutProps) => {
  const {
    pageHeaderVisible,
    rightBoxVisible = true,
    children,
    hideFooter = true,
    useNewFooter = false,
    showFooterLinks = false,
  } = props
  const [isScrolling, setIsScrolling] = useState(false)
  let scrollRef = useRef<HTMLElement>(null)
  const [userInfoInited, setInited] = useState(false)
  useMount(() => {
    if (userService.initialed) {
      setInited(true)
      return
    }
    ee.on('userServiceInitialed', () => {
      setInited(true)
    })
  })
  if (!userInfoInited) {
    return (
      <div className={classNames(style.loadingBox)}>
        <Spin></Spin>
      </div>
    )
  }

  return (
    <div
      className={classNames(style.globalLayout, {
        [style.withPageHeader]: pageHeaderVisible,
        [style.hidePageFooter]: hideFooter,
      })}
    >
      {pageHeaderVisible && (
        <PageHeader isScrolling={isScrolling} rightBoxVisible={rightBoxVisible}></PageHeader>
      )}

      <section
        className={classNames(style.pageContainer)}
        id='scroll'
        ref={scrollRef}
        onScroll={() => {
          if (!scrollRef) {
            return
          }
          setIsScrolling(scrollRef.current!.scrollTop !== 0)
        }}
      >
        {children}
        {!hideFooter && useNewFooter && (
          <OnlineFooter
            showLinks={showFooterLinks}
            onCurrentTabClick={() => (scrollRef.current!.scrollTop = 0)}
          />
        )}
      </section>

      {!hideFooter && !useNewFooter && (
        <section className={classNames(style.pageFooter)}>
          <span>
            2016-2024 武汉夜莺科技有限公司
            <a
              className={classNames(style.linkBtn)}
              rel='nofollow noreferrer'
              target='_blank'
              href='https://beian.miit.gov.cn/'
            >
              鄂ICP备15002531号-16
            </a>
            <a
              className={classNames(style.linkBtn)}
              rel='nofollow noreferrer'
              target='_blank'
              href='https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006869'
            >
              鄂公网安备 42018502006869号
            </a>
            <span className={classNames(style.companyIntro)}>
              公司简介
              <span className={classNames(style.companyIntroDetailWrapper)}>
                <span className={classNames(style.companyIntroDetail)}>
                  <img src='https://assets.weibanzhushou.com/web/pt-web/home-page_icon__logo.1b9e042059a1.png' />
                  <div className={classNames(style.dividerH)}></div>
                  <div className={classNames(style.subTitle)}>
                    <div className={classNames(style.divider)}></div>
                    公司简介
                  </div>
                  <div className={classNames(style.body)}>
                    夜莺科技成立于2015年，是一家专注于数字营销领域的互联网科技企业，致力于为企业提供数字化营销产品工具和综合解决方案，于23年推出AI翻译工具：会译-对照式翻译，旨在提升阅读效率。
                  </div>
                </span>
              </span>
            </span>
          </span>
          <span>
            <span style={{ marginRight: '10px' }}>地址：武汉市关山大道铭丰大厦2906</span>
            <span className={classNames(style.contactPhone)} style={{ marginRight: '10px' }}>
              联系电话：027-59764996
            </span>
            <span className={classNames(style.feedbackEmail)}>
              意见反馈：
              <a
                target='_blank'
                className={classNames(style.linkBtn)}
                href='mailto:mopengcheng@nightingales.cn'
                rel='noreferrer'
              >
                mopengcheng@nightingales.cn
              </a>
            </span>
          </span>
        </section>
      )}
    </div>
  )
})

export default GlobalLayout
