import classNames from 'classnames'
import style from './RegisterCheckPhoneNumber.module.less'
import { observer } from 'mobx-react'
import { commonUtils } from '@/helpers/commonUtils'
import { useState } from 'react'
import { useAsyncFn } from 'react-use'
import { api } from '@/helpers/api'
import { toastController } from '@/controllers/toastController'
import { Link } from 'react-router-dom'

type RegisterCheckPhoneNumberProps = {
  onSuccess: (number: string) => void
  showFooterLink?: boolean
}

export const RegisterCheckPhoneNumber = observer((props: RegisterCheckPhoneNumberProps) => {
  const { onSuccess, showFooterLink = false } = props
  const [phoneNumber, setPhoneNumber] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const [state, postPhoneNumber] = useAsyncFn(async (number: string) => {
    let result = ''
    try {
      const data = await api.login.registerPhoneNumber(number)
      result = data.statusMessage

      onSuccess(number)
      return result
    } catch (error) {
      result = commonUtils.formatErrorMsg(errMsg)
      toastController.error(result)
    }
    return result
  }, [])

  const checkNumber = () => {
    if (state.loading) {
      return
    }

    if (/^1[0-9]{10}$/.test(phoneNumber)) {
      setErrMsg('')
      postPhoneNumber(phoneNumber)
    } else {
      setErrMsg('*请输入正确的手机号')
    }
  }

  return (
    <div className={classNames(style.numberCheck)}>
      <p className={classNames(style.title)}>绑定手机号</p>
      <div className={classNames(style.numberWrap)}>
        <input
          type='number'
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value.trim())
          }}
          autoFocus
          className={style.input}
          placeholder='请输入您的手机号'
        />
        <p className={classNames(style.err)}>{errMsg}</p>
        {phoneNumber.length > 0 && (
          <div
            className={classNames(style.clear)}
            onClick={() => {
              setPhoneNumber('')
              setErrMsg('')
            }}
          ></div>
        )}
      </div>

      <div className={classNames(style.confirm)} onClick={() => checkNumber()}>
        <img
          className={classNames(style.icon)}
          src={commonUtils.getStaticFile('phone_check.png')}
        />
        <span className={classNames(style.text)}>绑定</span>
      </div>

      <p className={classNames(style.tip)}>
        客户您好，应国家要求需您绑定手机号，不会泄漏给第三方，请放心输入！
      </p>

      {showFooterLink && (
        <section className={style.footerBox}>
          <Link
            target='_blank'
            to='https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8F%8A%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.2e0d8664cd55.pdf'
          >
            用户协议
          </Link>
          <Link
            target='_blank'
            to='https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E6%94%BF%E7%AD%96.bbb180e220e9.pdf'
          >
            隐私政策
          </Link>
        </section>
      )}
    </div>
  )
})
