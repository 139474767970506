import { EventEmitter } from 'events'

/** 事件定义在此处 */
interface Events {
  /** 用户信息初始化完成 */
  userServiceInitialed: {}

  versionInfoChanged: {
    version: string
    updateDate: string
    downloadUrl: string
    hy360DownloadUrl: string
  }
}

class LocalEventEmitter<E extends Events> {
  private ee: EventEmitter
  constructor() {
    this.ee = new EventEmitter()
  }
  public emit<K extends keyof E>(eventName: K, args: E[K]): boolean {
    return this.ee.emit(eventName as string, args)
  }
  public on<K extends keyof E>(eventName: K, listener: (args: E[K]) => void) {
    return this.ee.on(eventName as string, listener)
  }
  public once<K extends keyof E>(eventName: K, listener: (args: E[K]) => void) {
    return this.ee.once(eventName as string, listener)
  }
  public off<K extends keyof E>(eventName: K, listener: (args: E[K]) => void) {
    return this.ee.off(eventName as string, listener)
  }
}

export const ee = new LocalEventEmitter()
