import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

export interface UserActionInfo {
  userId?: string
  product?: string
  // 客户端类型
  clientType?: string
  // 视图载体
  carrier?: string
  // "所属模块"
  module?: string
  // 模块功能点
  function?: string
  // "对应位置"
  position?: string
  // 行为类型
  action?: string
  // 行为描述
  actionDesc?: string
  // 自定义字段,根据需要存放内容
  remark?: any
  // 站点
  site?: string
  // 元素内容
  element?: string
}

export namespace user {
  export function doLogin(token: string) {
    return post('/user/login', {}, { token })
  }
  export function getUserInfo(): Promise<ApiBaseResult & { userData: ServerDataTypes.UserInfo }> {
    return get('/user/me')
  }
  export function logout(): Promise<ApiBaseResult> {
    return get('/user/logout')
  }

  // 记录用户访问行为
  export function postVisitorRecord(data: { token: string; actionType: string }) {
    return post('/visitor/record', {}, data)
  }

  export function postLenovoRecord(data: UserActionInfo) {
    return post('/user_behavior/burying', {}, data)
  }

  export function postUninstallRecord(
    data: ServerDataTypes.UninstallRecordData
  ): Promise<{ recordId: string }> {
    return post('/uninstall_record', {}, data)
  }

  export function checkAlipaySignStatus() {
    return post('/alipay/agree_status')
  }

  export function alipayUnsign() {
    return post('/alipay/unsign_agree')
  }

  export function getEventRecordList(params: {
    offset: number
    limit: number
    //目前只有PDF
    eventType?: ServerDataTypes.EventType
  }): Promise<ApiBaseResult & { data: ServerDataTypes.EventRecordInfo[] }> {
    return get('/event_record/list', params)
  }

  export function getLanguageList(): Promise<
    ApiBaseResult & {
      language_list: {
        [key: string]: { name: string; nativeName: string; chineseName?: string }
      }
    }
  > {
    return post('/language/list', {}, {}, false)
  }

  export function getUserTranslateCount(useType?: PostDataTypes.UseType): Promise<
    ApiBaseResult & {
      data: {
        base_data: ServerDataTypes.TranslateCountMap
        use_data: { [key: string]: { count: number } }
      }
    }
  > {
    return get('/user/translate/count', { useType }, false)
  }

  // 检查是否满足教育特惠活动条件
  export function checkEnableEduActivity() {
    return get<{
      match: boolean
    }>('/user/edu_activity')
  }
}
