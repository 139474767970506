/* eslint-disable @typescript-eslint/member-ordering */

import { api } from '@/helpers/api'
import { BuyVipServiceTypes as Types } from './serviceTypes'
import { observable } from 'mobx'
import { BUY_RECORD_LIST, COMMENT_LIST } from './const'
import { resolve } from 'path'
type StorageData = Types.StorageData
class BuyVipService {
  public static serviceName = 'BuyVipService'
  @observable activityList: ServerDataTypes.ActivityData[] = []
  @observable triggerUpdatePlanList = false
  protected storagePrefix = ''
  protected defaultStorageValues: StorageData = {}
  protected onInit() {
    // this.getBuyVipDialogExtraData(
    //   JSON.stringify({
    //     commentList: COMMENT_LIST,
    //     buyRecordList: BUY_RECORD_LIST,
    //   })
    // )
  }
  constructor() {
    this.onInit()
  }

  public async createOrder(data: PostDataTypes.OrderCreateParams) {
    return api.pricing.createOrder(data)
  }

  public async pollOrderStatus(orderId: string) {
    return api.pricing.pollOrderStatus(orderId)
  }

  public async list() {
    let { planList } = await api.pricing.list()
    const matchActivity = await this.checkHasMatchedActivity('pay_page_top_banner')
    if (matchActivity?.data[0].planData) {
      const discountPlan = matchActivity.data[0].planData
      planList = planList.map((plan) => {
        if (
          plan.timeType === discountPlan.timeType &&
          plan.activeTime === discountPlan.activeTime
        ) {
          return {
            ...discountPlan,
            isDiscount: true,
          }
        }
        return plan
      })
    }
    return { planList, matchActivity: matchActivity || null }
  }

  public async updateActivityBuried(actionType: PostDataTypes.ActivityBuriedType) {
    try {
      await api.pricing.updateActivityBuried(actionType)
    } catch (error) {}
  }

  public async updateUserMatchActivity() {
    try {
      const { dataList } = await api.pricing.getUserMatchActivity()
      this.activityList = dataList || []
      return dataList || []
    } catch (error) {
      return []
    }
  }

  public setActivityConfigInfo(data: { hashId: string; configInfo: string }) {
    try {
      api.pricing.setActivityConfigInfo(data)
    } catch (error) {}
  }

  public async checkHasMatchedActivity(type: ServerDataTypes.ActivityPosition) {
    const activityList = await this.updateUserMatchActivity()
    return activityList.find((activity) => activity.showType === type) || null
  }

  public async receiveCoupon(couponHashId: string, cb?: (bool: boolean) => void) {
    try {
      await api.pricing.receiveCoupon(couponHashId)
      cb?.(true)
    } catch (error) {
      cb?.(false)
    }
  }

  public async getCouponList(status: 'expired' | 'not_expired') {
    try {
      const { dataList } = await api.pricing.getCouponList(status)
      return dataList || []
    } catch (error) {
      return []
    }
  }

  public async closeActivity(data: {
    activityHashId: string
    showType?: ServerDataTypes.ActivityPosition
  }) {
    try {
      await api.pricing.closeActivity(data)
    } catch (error) {}
  }

  public async saveActivityShow(data: {
    activityHashId: string
    showType: ServerDataTypes.ActivityPosition
    couponHashId?: string
    planId?: string
  }) {
    try {
      await api.pricing.saveActivityShow(data)
    } catch (error) {}
  }

  public async getBuyVipDialogExtraData(
    dataStr?: string
  ): Promise<ServerDataTypes.BuyVipDialogExtraData> {
    try {
      const { data } = await api.pricing.getBuyVipDialogExtraData(dataStr)
      return data
    } catch (error) {
      return {
        commentList: COMMENT_LIST,
        buyRecordList: BUY_RECORD_LIST,
      }
    }
  }

  public async getShowTopTipsPlans(planData: ServerDataTypes.VipPlan[]) {
    try {
      const { dataList } = await api.pricing.getShowTopTipsPlans(planData)
      return dataList
    } catch (error) {
      return []
    }
  }
}

export const buyVipService = new BuyVipService()
