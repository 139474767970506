/* eslint-disable complexity */
import { TranslateServiceType, translateService } from '@/services/translateService'
import { userService } from '@/services/userService'
import { debounce } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { api } from '../api'
import { commonUtils } from '../commonUtils'
import { storageHelper } from '../storageHelper'
import { PdfTranslateHelperConsts as Consts } from './consts'
import { PdfTranslateHelperTypes, PdfTranslateHelperTypes as Types } from './types'
// import { PdfTranslateHelperTypes.ImageInfo } from '@/helpers/pdfTranslateHelper/types'

type SentenceType = Types.SentenceType
type RowSentence = Types.RowSentence
type OriginalSentence = Types.OriginalSentence
type Paragraph = Types.Paragraph

/** 任务类型：分享 | 下载 */
type TranslateTaskType = 'share' | 'download'
/**
 * 任务状态：翻译中 | 翻译暂停 | 已完成 | '翻译失败'
 * 页面刷新后，翻译中的任务状态会自动切换到翻译暂停
 */
type TranslateTaskStatus = 'processing' | 'pending' | 'finished' | 'error'

export interface TranslateParams {
  containerEl: HTMLElement
  currentPage: number
  from: TranslateServiceType.LocaleWithAutoDetect
  to: TranslateServiceType.Locale
  shareHashId?: string
  fileId: number
}

export type TranslateTaskCallback = (data: {
  err: string | null
  status: TranslateTaskStatus
  /** 当前翻译任务的目标页数 */
  targetPage: number
  /** 已完成翻译的页数 */
  finishedPage: number
  translateData: Array<Types.PageData>
}) => void

export interface TranslateTask {
  fileId: number
  fileUrl: string
  type: TranslateTaskType
  status: TranslateTaskStatus
  from: TranslateServiceType.LocaleWithAutoDetect
  to: TranslateServiceType.Locale
  /** 开始翻译的页面 */
  startPage: number
  /** 当前翻译任务的目标页数 */
  targetPage: number
  /** 已翻译完成的页数 */
  finishedPage: number
}

class PdfTranslateHelper {
  private taskUid = commonUtils.genId()
  public PARSE_PAGE_COUNT = 3

  @observable downloadActiveInfo: {
    task: TranslateTask
    downloadOriginalContainerLoaded: (container: HTMLElement) => void
  } | null = null

  public parsedPageData: Array<Types.PageData> = []
  // 下载使用的数据
  public downloadParsedPageData: Array<Types.PageData> = []

  private taskList: Array<TranslateTask> = []

  private translateDebounceCall = debounce(
    (params: TranslateParams, callback: (data: Array<Types.PageData>) => void) => {
      const { currentPage, from, to, shareHashId, fileId } = params
      // 翻译当前页及前后三页
      let startIndex = currentPage - this.PARSE_PAGE_COUNT
      if (startIndex < 0) {
        startIndex = 0
      }
      const endIndex = currentPage + this.PARSE_PAGE_COUNT
      const pageData = this.parsedPageData.slice(startIndex, endIndex)
      this.translatePage(pageData, from, to, fileId, callback, shareHashId)
    },
    1000
  )

  /**
   * 获取文件对应翻译任务的状态
   * @param fileId 文件 id
   * @returns
   *   finishedPage: 指定 from&to 下，文件已完成翻译的页数
   *   lastTask 最后一次翻译任务的状态。为 null 时表示没有翻译任务
   */
  public async getTranslateTaskStatus({
    fileId,
    from,
    to,
  }: {
    fileId: number
    from: TranslateServiceType.LocaleWithAutoDetect
    to: TranslateServiceType.Locale
  }): Promise<{
    finishedPage: number
    lastTask: TranslateTask | null
  }> {
    let { pdfTranslateTaskList } = storageHelper.get(['pdfTranslateTaskList'])
    if (!pdfTranslateTaskList) {
      pdfTranslateTaskList = []
    }
    const getFinishedPage = async (): Promise<number> => {
      const hasContextTask = this.taskList.find(
        (task) => task.fileId === fileId && task.from === from && task.to === to
      )
      if (hasContextTask) {
        // console.log('return 1', hasContextTask.task.finishedPage)
        return hasContextTask.finishedPage
      }
      const storageTask = pdfTranslateTaskList.find(
        (task) => task.fileId === fileId && task.from === from && task.to === to
      )
      if (storageTask) {
        // console.log('return 2', storageTask.finishedPage)
        return storageTask.finishedPage
      }

      const {
        data: { fields },
      } = await api.pdf.getTranslateStatus(fileId)
      const targetTask = fields.find((f: any) => f.toLang === to && f.fromLang === from)
      const finishedPage = targetTask ? targetTask.translatePageNumber : 0
      // console.log('return 3', finishedPage)
      return finishedPage
    }
    const getLastTask = (): TranslateTask | null => {
      const hasContextTask = this.taskList.find((task) => task.fileId === fileId)
      if (hasContextTask) {
        return hasContextTask
      }
      const storageTask = pdfTranslateTaskList.find((task) => task.fileId === fileId)
      if (storageTask) {
        return storageTask
      }
      return null
    }

    const finishedPage = await getFinishedPage()
    const lastTask = getLastTask()
    return {
      finishedPage,
      lastTask,
    }
  }

  /** 创建翻译任务 */
  public async createTranslateTaskAccurate(
    {
      fileId,
      from,
      to,
      targetPage,
      type,
      totalPage,
      forceTranslate,
    }: {
      fileId: number
      type: TranslateTaskType
      from: TranslateServiceType.LocaleWithAutoDetect
      to: TranslateServiceType.Locale
      targetPage: number
      totalPage: number
      forceTranslate?: boolean
    },
    callback: TranslateTaskCallback
  ) {
    // console.log('createTranslateTask', fileId, from, to, targetPage, totalPage)
    // const finishedPage = 1
    const { finishedPage, lastTask } = await this.getTranslateTaskStatus({ fileId, from, to })
    if (lastTask && lastTask.status === 'processing') {
      callback({
        err: '当前文件正在翻译中，请稍后再试',
        status: 'error',
        targetPage,
        finishedPage,
        translateData: [],
      })
      return
    }
    if (!forceTranslate && finishedPage >= targetPage) {
      callback({
        err: null,
        status: 'finished',
        targetPage,
        finishedPage,
        translateData: [],
      })
      return
    }
    // //移除同一个文件的已有任务
    this.taskList = this.taskList.filter((task) => task.fileId !== fileId)

    this.downloadActiveInfo = null
    const { downloadUrl } = await api.pdf.getFileUrl(fileId)
    const task: TranslateTask = {
      fileId,
      fileUrl: downloadUrl,
      startPage: forceTranslate ? 1 : finishedPage + 1,
      targetPage,
      finishedPage,
      from,
      to,
      status: 'processing',
      type,
    }
    // 翻译完成后的回调
    const translatedCallback: TranslateTaskCallback = async ({
      err,
      status,
      targetPage,
      finishedPage,
      translateData,
    }) => {
      // 记录翻译状态
      this.recordTranslateStatusDebounceCall(
        {
          fileId,
          fromLang: from,
          toLang: to,
          pageNumber: finishedPage,
          totalPageNumber: totalPage,
        },
        () => {
          // 更新状态
          this.taskList.some((t) => {
            if (t.fileId === fileId) {
              t.finishedPage = finishedPage
              t.status = status
              return true
            }
            return false
          })
          // console.log('record translate status success', { task, })
          // 更新到 storage
          if (task.fileId === fileId) {
            task.finishedPage = finishedPage
            const storageTask: TranslateTask = {
              ...task,
              // storage 中的状态要么为 finished，要么为 pending
              status: status === 'finished' ? 'finished' : 'pending',
            }
            this.updateTaskToStorage(storageTask)
          }

          if (status === 'finished') {
            // 回调数据给业务方
            callback({
              err,
              status,
              targetPage,
              finishedPage,
              translateData,
            })
          }
        }
      )
      if (status !== 'finished') {
        // 回调数据给业务方
        callback({
          err,
          status,
          targetPage,
          finishedPage,
          translateData,
        })
      }
    }

    // 当原文的组件DOM渲染完之后，执行这个回调开始获取原文中的DOM，并且开始翻译
    const downloadOriginalContainerLoaded: (container: HTMLElement) => void = async (container) => {
      const pageData = await this.translateTargetPage({
        containerEl: container,
        startPage: task.startPage,
        targetPage,
      })

      // 翻译pageData数据
      pageData.sort((a, b) => a.pageNum - b.pageNum)
      const totalCount = pageData.length

      const doTranslate = async (startIdx: number) => {
        const endIdx = Math.min(startIdx + 5, totalCount)
        const dataList = pageData.slice(startIdx, endIdx)
        // console.log('do translate', startIdx, endIdx, totalCount, dataList)
        await this.translatePage(dataList, from, to, fileId, () => {})

        let errMsg: string | null = null
        dataList.some(({ paragraphs }) => {
          if (paragraphs.length === 0) {
            return false
          }
          // 只要有一个段落翻译失败，就认为整个页面翻译失败
          errMsg = paragraphs[0].errMsg
          return errMsg
        })
        let status: TranslateTaskStatus = endIdx >= totalCount ? 'finished' : 'processing'
        let finishedPage = task.startPage - 1 + endIdx
        if (errMsg) {
          status = 'error'
          finishedPage = task.startPage
        }
        translatedCallback({
          err: errMsg,
          status,
          finishedPage,
          targetPage,
          translateData: pageData,
        })
        // 继续翻译。直到翻译完成
        if (status === 'processing') {
          doTranslate(endIdx)
        }
      }
      doTranslate(0)
    }

    this.downloadActiveInfo = {
      task,
      downloadOriginalContainerLoaded,
    }

    // 更新到上下文
    this.taskList.push(task)
    // 更新到 storage
    const storageTask: TranslateTask = {
      ...task,
      // storage 中的状态初始为 pending
      status: 'pending',
    }
    this.updateTaskToStorage(storageTask)
  }

  private async translateTargetPage({
    containerEl,
    startPage,
    targetPage,
  }: {
    containerEl: HTMLElement
    startPage: number
    targetPage: number
  }) {
    const promiseArray: Array<Promise<Types.PageData>> = []
    const pageEls = containerEl.querySelectorAll('.pc')
    for (let i = 0; i < pageEls.length; i++) {
      if (i >= startPage - 1 && i < targetPage) {
        const pageEl = pageEls[i] as HTMLElement
        // promiseArray.push(this.printParseHtml(i, containerEl))
        promiseArray.push(this.parsePage(i, pageEl, false))
      }
    }
    const pageDataList = await Promise.all(promiseArray)
    pageDataList.sort((a, b) => a.pageNum - b.pageNum)
    return pageDataList
  }

  public init() {
    makeObservable(this)
  }

  public resetStatus(resetType: 'all' | 'result') {
    this.updateTaskUid()
    if (resetType === 'all') {
      // 清空解析结果。应用于切换文件的场景
      this.parsedPageData = []
    } else {
      // 重置翻译结果。应用于切换语言的场景
      this.parsedPageData.forEach(({ paragraphs, imgs }) => {
        paragraphs.forEach((p) => {
          p.translateResult = ''
        })
        imgs.forEach((img) => {
          img.translateResult = ''
        })
      })
    }
  }

  public updateTaskUid() {
    this.taskUid = commonUtils.genId()
  }

  public async doTranslate(
    params: TranslateParams,
    callback: (data: Array<Types.PageData>) => void
  ) {
    console.log('start translate......')
    // 解析 pdf
    const parsedPageData = await this.tryParseHtml(params)
    // console.log('parsedPageData', parsedPageData)
    callback(parsedPageData)
    setTimeout(() => {
      // 异步处理翻译逻辑
      this.translateDebounceCall(params, callback)
    })
  }

  private async tryParseHtml({
    containerEl,
    currentPage,
  }: TranslateParams): Promise<Array<Types.PageData>> {
    const promiseArray: Array<Promise<Types.PageData>> = []
    const pageEls = containerEl.querySelectorAll('.pc')

    for (let i = 0; i < pageEls.length; i++) {
      // 解析当前页及前后三页
      // if (i >= currentPage - PARSE_PAGE_COUNT && i < currentPage + PARSE_PAGE_COUNT) {
      //   promiseArray.push(this.parseHtml(i, containerEl))
      // }
      const page = pageEls[i] as HTMLElement
      const pageNumber = parseInt(page.getAttribute('data-page')!)
      promiseArray.push(this.parsePage(pageNumber, page, true))
    }
    const pageDataList = await Promise.all(promiseArray)
    pageDataList.sort((a, b) => a.pageNum - b.pageNum)
    return pageDataList
  }

  public parsePageElement(pageEl: HTMLElement) {
    const sentenceElList: Array<HTMLElement> = []
    const imgElList: Array<HTMLImageElement> = []
    const links: string[] = []

    // 从原始 html 中提取出所有的句子（文本和公式）
    Array.from(pageEl.children).forEach((_el) => {
      const el = _el as HTMLElement
      if (el.classList.contains('t') || el.classList.contains('c')) {
        sentenceElList.push(el)
      } else {
        // 隐藏除背景图外的其他元素
        if (el.classList.contains('bi')) {
          imgElList.push(el as HTMLImageElement)
        } else {
          if (el.classList.contains('l')) {
            const l = el.getAttribute('href') as string
            if (links.indexOf(l) === -1) {
              links.push(l)
            }
          }
          el.style.display = 'none'
        }
      }
    })

    const { pageWidth, pageHeight } = this.getHtmlPageSize(pageEl as HTMLElement)

    const originalSentences = this.processHtmlElToOriginalSentence(sentenceElList, {
      pageWidth,
      pageHeight,
    })
    // console.log('originalSentence', originalSentences)
    const rowOriginalSentences = this.mergeOriginalSentenceToRow(originalSentences)
    // console.log('rowOriginalSentence', rowOriginalSentences)
    const paragraphs = this.mergeSentenceToParagraphs(rowOriginalSentences, links)
    paragraphs.forEach((p) => {
      const idxList: number[] = []
      p.links = links.filter((l) => {
        const idx = p.mergedText.indexOf(l)
        if (idx > -1 && idxList.indexOf(idx) === -1) {
          idxList.push(idx)
          return true
        }
        return false
      })
    })
    const imagesInfo = this.processImageEl(imgElList)
    // console.log(currentPage, 'paragraphs', paragraphs)

    const formulaAndSymbol = rowOriginalSentences.filter((row) => row.type !== 'text')
    this.processFormulaStyle(formulaAndSymbol)

    this.renderOriginalHtml(paragraphs)
    return {
      pageWidth,
      pageHeight,
      paragraphs,
      imagesInfo,
      formulaAndSymbol,
    }
  }

  public async parsePage(currentPage: number, pageEl: HTMLElement, translationParse: boolean) {
    if (translationParse) {
      if (this.parsedPageData[currentPage]) {
        return this.parsedPageData[currentPage]
      }
    }
    const { pageWidth, pageHeight, paragraphs, imagesInfo, formulaAndSymbol } =
      this.parsePageElement(pageEl)

    const pageData = {
      width: pageWidth,
      height: pageHeight,
      pageNum: currentPage,
      paragraphs,
      formulaAndSymbol,
      imgs: imagesInfo.map((info) => {
        return {
          imageStyleInfo: info,
          originalSrc: info.src,
          fileId: '',
          translateResult: '',
          id: commonUtils.genId(),
          needTranslate: paragraphs.length < 4 ? true : false,
          loading: paragraphs.length < 4 ? true : false,
        }
      }),
    }

    if (translationParse) {
      this.parsedPageData[currentPage] = pageData

      return pageData
    } else {
      return pageData
    }
  }

  public async parsePageDownload(currentPage: number, pageEl: HTMLElement) {
    const { pageWidth, pageHeight, paragraphs, imagesInfo, formulaAndSymbol } =
      this.parsePageElement(pageEl)

    return {
      width: pageWidth,
      height: pageHeight,
      pageNum: currentPage,
      paragraphs,
      formulaAndSymbol,
      imgs: imagesInfo.map((info) => {
        return {
          imageStyleInfo: info,
          originalSrc: info.src,
          fileId: '',
          translateResult: '',
          id: commonUtils.genId(),
          needTranslate: paragraphs.length < 4 ? true : false,
          loading: paragraphs.length < 4 ? true : false,
        }
      }),
    }
  }

  private processFormulaStyle(formulaAndSymbol: PdfTranslateHelperTypes.RowSentence[]) {
    formulaAndSymbol.forEach((formula) => {
      const setElementStyle = (el: HTMLElement) => {
        const styleCss = window.getComputedStyle(el)
        const {
          left,
          bottom,
          position,
          width,
          height,
          border,
          padding,
          margin,
          overflow,
          display,
          letterSpacing,
          fontSize,
          fontFamily,
          textShadow,
          wordSpacing,
          lineHeight,
        } = styleCss
        el.style.left = left
        el.style.bottom = bottom
        el.style.position = position
        el.style.width = width
        el.style.height = height
        el.style.border = border
        el.style.padding = padding
        el.style.overflow = overflow
        el.style.margin = margin
        el.style.display = display
        el.style.letterSpacing = letterSpacing
        el.style.fontSize = fontSize
        el.style.fontFamily = fontFamily
        el.style.textShadow = textShadow
        el.style.wordSpacing = wordSpacing
        el.style.lineHeight = lineHeight
      }

      const getAllChildren = (element: HTMLElement) => {
        setElementStyle(element)
        for (let i = 0; i < element.children.length; i++) {
          const child = element.children[i] as HTMLElement

          getAllChildren(child)
        }
      }

      getAllChildren(formula.el)
    })
  }

  private async printParseHtml(
    currentPage: number,
    containerEl: HTMLElement
  ): Promise<Types.PageData> {
    const pageEls = containerEl.querySelectorAll('.pc')
    const pageEle = pageEls[currentPage] as HTMLElement

    const { pageWidth, pageHeight, paragraphs, imagesInfo, formulaAndSymbol } =
      this.parsePageElement(pageEle)

    const pageData = {
      width: pageWidth,
      height: pageHeight,
      pageNum: currentPage,
      paragraphs,
      formulaAndSymbol,
      imgs: imagesInfo.map((info) => {
        return {
          imageStyleInfo: info,
          originalSrc: info.src,
          fileId: '',
          translateResult: '',
          isTranslated: false,
          id: commonUtils.genId(),
          needTranslate: false,
          loading: false,
        }
      }),
    }

    this.downloadParsedPageData[currentPage] = pageData

    return this.downloadParsedPageData[currentPage]
  }

  /** 渲染合并过后的 html */
  private renderOriginalHtml(paragraphs: Array<Paragraph>) {
    paragraphs
      .filter((s) => {
        return s.type === 'text'
      })
      .forEach(
        ({
          left,
          bottom,
          width,
          height,
          color,
          textShadow,
          webkitTextStrokeColor,
          webkitTextStrokeWidth,
          wordSpacing,
          letterSpacing,
          fontSize,
          type,
          textIndent,
          fontFamily,
          transform,
          mergedText,
          rowSentences,
          el,
        }) => {
          el.innerText = mergedText
          el.style.position = 'absolute'
          el.style.bottom = `${bottom}px`
          el.style.left = `${left}px`
          el.style.color = color
          const { a: scaleX, d: scaleY } = new DOMMatrix(transform)
          // 渲染实际宽高时需要考虑缩放比例
          const computedWidth = width / scaleX
          const computedHeight = height / scaleY
          el.style.width = `${computedWidth}px`
          el.style.height = `${computedHeight}px`
          el.style.lineHeight = `${computedHeight / rowSentences.length}px`
          el.style.textShadow = textShadow
          el.style.webkitTextStrokeColor = webkitTextStrokeColor
          el.style.webkitTextStrokeWidth = webkitTextStrokeWidth
          el.style.wordSpacing = wordSpacing
          el.style.letterSpacing = letterSpacing
          // 渲染实际字号时需要考虑缩放比例
          const computedFontSize = fontSize / scaleY
          el.style.fontSize = `${computedFontSize}px`
          el.style.fontFamily = fontFamily
          el.style.transform = transform
          if (textIndent) {
            el.style.textIndent = '2em'
          }
          el.style.overflow = 'hidden'

          el.classList.add('t')
          if (rowSentences.length > 1) {
            el.style.whiteSpace = 'pre-wrap'
          }
          // 将合并元素插入到原元素的前面
          rowSentences[0].originalSentences[0].el.before(el)
          // 移除掉参与合并的原元素
          rowSentences.forEach((r) => {
            r.originalSentences.forEach((os) => {
              if (os.type === 'formulas') {
                const { bottom, left, width, height, transform } = os
                const { a: scaleX, d: scaleY } = new DOMMatrix(transform)
                const computedWidth = width / scaleX
                const computedHeight = height / scaleY
                const coverEl = document.createElement('span')
                coverEl.classList.add('formulas-cover')
                coverEl.style.width = `${computedWidth}px`
                coverEl.style.height = `${computedHeight}px`
                coverEl.style.bottom = `${bottom}px`
                coverEl.style.left = `${left}px`
                os.el.after(coverEl)
              }
              os.el.remove()
            })
          })
        }
      )
  }

  private processImageEl(imageElements: HTMLImageElement[]) {
    const imgsInfo: PdfTranslateHelperTypes.ImageStyleInfo[] = []

    imageElements.forEach((img) => {
      const styleData = window.getComputedStyle(img)
      // console.log(img, style)
      const src = img.src
      const imageInfo: PdfTranslateHelperTypes.ImageStyleInfo = {
        src,
        cssStyle: {
          zIndex: styleData.zIndex,
          left: styleData.left,
          bottom: styleData.bottom,
          top: styleData.top,
          right: styleData.right,
          width: styleData.width,
          height: styleData.height,
          position: 'absolute',
          userSelect: 'none',
          border: styleData.border,
          margin: styleData.margin,
        },
      }

      imgsInfo.push(imageInfo)

      img.remove()
    })

    return imgsInfo
  }

  private getHtmlPageSize(pageEl: HTMLElement): {
    pageWidth: number
    pageHeight: number
  } {
    const { clientWidth: width, clientHeight: height } = pageEl
    return {
      pageWidth: width,
      pageHeight: height,
    }
  }

  /** 单行合并：将应该属于同一行的句子合并为一个同一个行 */
  private mergeOriginalSentenceToRow(
    originalSentence: Array<OriginalSentence>
  ): Array<RowSentence> {
    const rowSentences: Array<RowSentence> = []
    originalSentence.forEach((os) => {
      const preSentence = rowSentences[rowSentences.length - 1]
      const newRowSentence: RowSentence = {
        ...os,
        mergedText: os.type === 'text' ? os.text : os.type === 'formulas' ? '[latex]' : '',
        originalSentences: [os],
      }

      // console.log(preSentence, os)
      // 合并前后行数据
      const doMerge = (rs: RowSentence, os: OriginalSentence) => {
        // console.log('do merge', rs.mergedText, os.text)
        rs.right = Math.max(rs.right, os.right)
        rs.width = rs.right - rs.left
        rs.height = Math.max(rs.height, os.height)
        rs.originalSentences.push(os)
        // 如果合并行中存在文本元素，则认为该合并行的类型为 text，且以文本元素的样式作为合并后的样式
        //（因为一般情况下，公式要么独占一行，要么被文本包含。符合会被文本包含）
        if (
          // 前后行格式不一致，且后行为文本元素
          (rs.type !== 'text' && os.type === 'text') ||
          // 类型相同时，取文本长度较长的行的样式作为基准
          (os.type === 'text' && os.text.length >= 5 && rs.mergedText.length < 5)
        ) {
          rs.type = 'text'
          rs.top = os.top
          rs.bottom = os.bottom
          rs.lineHeight = os.lineHeight
          rs.color = os.color
          rs.textShadow = os.textShadow
          rs.webkitTextStrokeColor = os.webkitTextStrokeColor
          rs.webkitTextStrokeWidth = os.webkitTextStrokeWidth
          rs.wordSpacing = os.wordSpacing
          rs.letterSpacing = os.letterSpacing
          rs.fontSize = os.fontSize
          rs.fontFamily = os.fontFamily
          rs.transform = os.transform
        }
        if (os.type === 'text') {
          rs.mergedText += os.text
        } else if (os.type === 'formulas') {
          rs.mergedText += ' [latex] '
        }
      }

      // NOTE: for test
      const tryConsole = (idx: number, row: RowSentence, os: OriginalSentence) => {
        if (row.mergedText === 'F') {
          console.log('merge s', idx, row, os)
        }
        // console.log('merge s', idx, row, os)
      }

      if (!preSentence) {
        // 初始化 rowSentence
        rowSentences.push(newRowSentence)
      } else if (preSentence.type !== os.type) {
        // 处理前后行类型不一致的情况
        // 合并公式与文本的情况
        if (
          (preSentence.type === 'formulas' && os.type === 'text') ||
          (preSentence.type === 'text' && os.type === 'formulas')
        ) {
          if (!this.isSameLineSentence(preSentence, os)) {
            rowSentences.push(newRowSentence)
            tryConsole(1, preSentence, os)
            return
          }
          doMerge(preSentence, os)
        }
        // 合并符号与文本/公式的情况
        if (
          (preSentence.type === 'symbol' && os.type === 'text') ||
          (preSentence.type === 'text' && os.type === 'symbol') ||
          (preSentence.type === 'symbol' && os.type === 'formulas') ||
          (preSentence.type === 'formulas' && os.type === 'symbol')
        ) {
          if (!this.isSubSymbolSentence(preSentence, os)) {
            rowSentences.push(newRowSentence)
            tryConsole(3, preSentence, os)
            return
          }
          doMerge(preSentence, os)
        }
      } else {
        // 单独处理一些特殊情况，比如CO2 2是下标的情况
        const curText = os.text.replaceAll(' ', '')
        const reg = /^[-,−]?\d+$/
        if (reg.test(curText) && os.fontSize < preSentence.fontSize) {
          doMerge(preSentence, os)
          return
        }
        // console.log(curText)
        // 如果只有一个标点，应该要合并，这里先只校验一个长度是不是1
        if (curText.length === 1) {
          doMerge(preSentence, os)
          return
        }
        // 处理前后行类型一致的情况（文本与文本、公式与公式、符合与符号）
        if (!this.isSameComputedStyle(preSentence, os)) {
          // 基础样式是否相同
          rowSentences.push(newRowSentence)
          tryConsole(4, preSentence, os)
          return
        }

        if (!this.isSameLineSentence(preSentence, os)) {
          rowSentences.push(newRowSentence)
          tryConsole(5, preSentence, os)
          return
        }
        doMerge(preSentence, os)
      }
    })
    const parsedRowSentences: Array<RowSentence> = []
    rowSentences.forEach((rs) => {
      // 判断 text 的长度是否小于 formulas 的长度，若小于，则不做合并
      // 合并进 text 后的 formulas 不会参与翻译，因此当公式足够长时，
      // 应该保留公式的原始样式，而不是合并进 text 中
      let formulasLength = 0
      let textLength = 0

      const { originalSentences } = rs
      originalSentences.forEach((os) => {
        if (os.type === 'formulas') {
          formulasLength += os.text.length
        } else if (os.type === 'text') {
          textLength += os.text.length
        }
      })
      const totalLength = formulasLength + textLength
      if (formulasLength / totalLength > 0.7) {
        originalSentences.forEach((os) => {
          const newRowSentence: RowSentence = {
            ...os,
            mergedText: os.type === 'text' ? os.text : os.type === 'formulas' ? '[latex]' : '',
            originalSentences: [os],
          }
          parsedRowSentences.push(newRowSentence)
        })
      } else {
        parsedRowSentences.push(rs)
      }
    })
    return parsedRowSentences
  }

  /** 单行合并：判断是否属于同一行 */
  private isSameLineSentence(pre: RowSentence, cur: OriginalSentence) {
    return (
      this.equal(pre.right, cur.left, pre.fontSize) &&
      this.equal(pre.bottom, cur.bottom, cur.type === 'text' ? pre.height / 2 : pre.height)
    )
  }

  /** 单行合并：合并符号 */
  private isSubSymbolSentence(pre: RowSentence, cur: OriginalSentence) {
    const gap = pre.type === 'symbol' ? pre.fontSize : cur.fontSize
    const isTopNear = this.equal(pre.top, cur.top, gap)
    const isBottomNear = this.equal(pre.bottom, cur.bottom, gap)
    const isLeftNear = this.equal(pre.right, cur.left, gap)
    const isRightNear = this.equal(cur.right, pre.left, gap)
    return (isTopNear && (isLeftNear || isRightNear)) || (isBottomNear && isRightNear)
  }

  /** 多行合并：将处于上下行，但应该属于同一段的句子，合并为同一个段落 */
  private mergeSentenceToParagraphs(
    rowSentences: Array<RowSentence>,
    links: string[]
  ): Array<Paragraph> {
    const paragraphs: Array<Paragraph> = []
    rowSentences
      .filter((rs) => {
        return rs.type === 'text' && rs.mergedText.trim()
      })
      .forEach((rs) => {
        let preParagraph = paragraphs[paragraphs.length - 1]
        // if (rs.text.startsWith('fieldsexertneuroprotective[18,19]andremodelingef-')) {
        //   console.log('rs.text:', rs.text)
        // }
        const newParagraph: Paragraph = {
          ...rs,
          id: commonUtils.genId(),
          errMsg: null,
          translateResult: '',
          mergedText: rs.mergedText,
          rowSentences: [rs],
          el: document.createElement('p'),
          replaceFormulaSentences: [],
          links: [],
        }
        const doMerge = (
          p: Paragraph,
          rs: RowSentence,
          {
            textIndent,
          }: {
            textIndent?: boolean
          }
        ) => {
          p.left = Math.min(p.left, rs.left)
          p.right = Math.max(p.right, rs.right)
          p.bottom = rs.bottom
          p.width = p.right - p.left
          // Sentence 以页面左下角为原点，因此这里用 top - bottom 作为高度
          p.height = p.top - p.bottom
          // 处理单词被分割的情况
          if (p.mergedText.endsWith('-')) {
            p.mergedText += rs.mergedText
          } else {
            // 如果是链接，则不加空格
            if (
              links.some((l) => {
                return (
                  !p.mergedText ||
                  (!p.mergedText.includes(l) &&
                    !rs.mergedText.includes(l) &&
                    (p.mergedText + rs.mergedText).includes(l))
                )
              })
            ) {
              p.mergedText += rs.mergedText
            } else {
              p.mergedText += ` ${rs.mergedText}`
            }
            // p.mergedText += p.mergedText ? ` ${rs.mergedText}` : rs.mergedText
          }
          if (textIndent) {
            p.textIndent = textIndent
          }
          p.rowSentences.push(rs)
        }
        // NOTE: for test
        const tryConsole = (idx: number, p: Paragraph, rs: RowSentence) => {
          // if (p.mergedText.includes('INTRODUCTION: MOMENTS OF')) {
          //   console.log('merge m', idx, p, rs)
          // }
          // console.log('merge m', idx, p, rs)
        }
        if (!preParagraph) {
          paragraphs.push(newParagraph)
        } else {
          // 判断上下行是否为同一类型
          if (preParagraph.type !== rs.type) {
            tryConsole(6, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 判断上下行是否有以 symbol 开始或结尾的行
          if (this.isStartOrEndWidthSymbol(preParagraph, rs)) {
            tryConsole(7, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 上下行样式是否一致
          if (!this.isSameComputedStyle(preParagraph, rs)) {
            tryConsole(8, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 上下行距离是否处于「同一段」的距离内
          if (!this.isNearSentence(preParagraph, rs)) {
            tryConsole(9, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 上下行是否超过页面宽度的一半
          if (!this.isMoreThanHalfWidth(preParagraph, rs)) {
            tryConsole(10, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 上下行是否有列表项
          if (this.isBulletSentence(preParagraph, rs)) {
            tryConsole(11, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 上下行是否有目录
          if (this.isTableOfContent(preParagraph, rs)) {
            tryConsole(12, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 上下行是否存在表格项
          if (this.isTableItem(preParagraph, rs)) {
            tryConsole(13, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 两端对齐
          if (this.isJustifyAlignSentence(preParagraph, rs)) {
            tryConsole(1, preParagraph, rs)
            doMerge(preParagraph, rs, {})
            return
          }
          // 第一行左缩进
          if (this.isIndentSentence(preParagraph, rs)) {
            tryConsole(3, preParagraph, rs)
            doMerge(preParagraph, rs, { textIndent: true })
            return
          }
          // 前一句是否以句末点号为结尾
          if (this.isEndPunctuation(preParagraph)) {
            tryConsole(14, preParagraph, rs)
            paragraphs.push(newParagraph)
            return
          }
          // 左对齐
          if (this.isAlignLeftSentence(preParagraph, rs)) {
            tryConsole(2, preParagraph, rs)
            doMerge(preParagraph, rs, {})
            return
          }
          // 第二行左缩进
          if (this.isRefererSentence(preParagraph, rs)) {
            tryConsole(4, preParagraph, rs)
            doMerge(preParagraph, rs, {})
            return
          }
          // 上下两行居中对齐
          if (this.isJustifyCenterSentence(preParagraph, rs)) {
            tryConsole(5, preParagraph, rs)
            doMerge(preParagraph, rs, {})
            return
          }
          tryConsole(15, preParagraph, rs)
          paragraphs.push(newParagraph)
        }
      })
    return paragraphs
  }

  /** 两行合并：上下行是否有以 symbol 开始或结尾的行 */
  private isStartOrEndWidthSymbol(pre: Paragraph, cur: RowSentence) {
    return (
      pre.rowSentences[0].type === 'symbol' ||
      pre.rowSentences[pre.rowSentences.length - 1].type === 'symbol' ||
      cur.originalSentences[0].type === 'symbol' ||
      cur.originalSentences[cur.originalSentences.length - 1].type === 'symbol'
    )
  }

  /** 两行合并：上下行是否有列表项 */
  private isBulletSentence(pre: Paragraph, cur: RowSentence) {
    return (
      // 如 • xxx 或 •xxx
      (this.startWith(pre.mergedText, Consts.LIST_STYLE_PREFIXES) &&
        this.startWith(cur.mergedText, Consts.LIST_STYLE_PREFIXES)) ||
      // 如：1. xxx 或 1.xxx
      /^\d+\..+/.test(pre.mergedText) ||
      /^\d+\..+/.test(cur.mergedText)
    )
  }

  /** 两行合并：上下行是否有目录 */
  private isTableOfContent(pre: Paragraph, cur: RowSentence) {
    return (
      // 如: xxx....12 或 xxx.... 12
      /\.{3,}\s*\d+$/.test(pre.mergedText) ||
      /\.{3,}\s*\d+$/.test(cur.mergedText) ||
      // 如：xxx. . .12 或 xxx. . . 12
      /[.\s]{3,}\s*\d+$/.test(pre.mergedText) ||
      /[.\s]{3,}\s*\d+$/.test(cur.mergedText)
    )
  }

  /** 两行合并：上下行是否有表格项 */
  private isTableItem(pre: Paragraph, cur: RowSentence) {
    return pre.mergedText.endsWith('√') || cur.mergedText.endsWith('√')
  }

  /** 两行合并：前一行以句末点号为结尾 */
  private isEndPunctuation(pre: Paragraph) {
    const endPunctuations = ['.', '!', '?', '。', '！', '？']
    return endPunctuations.some((p) => {
      return pre.mergedText.endsWith(p)
    })
  }

  /** 两行合并：上下行距离是否介于行高内 */
  private isNearSentence(pre: Paragraph, cur: RowSentence) {
    return this.equal(pre.bottom, cur.top, pre.lineHeight)
  }

  /** 两行合并：两端对齐 */
  private isJustifyAlignSentence(pre: Paragraph, cur: RowSentence) {
    const lastRowSentence = pre.rowSentences[pre.rowSentences.length - 1]
    return (
      this.equal(lastRowSentence.left, cur.left, 1.5) &&
      this.equal(lastRowSentence.right, cur.right, 2)
    )
  }

  /** 两行合并：左对齐 */
  private isAlignLeftSentence(pre: Paragraph, cur: RowSentence) {
    const lastRowSentence = pre.rowSentences[pre.rowSentences.length - 1]
    let adjustLeft = lastRowSentence.left
    // 上一行以引号开头，需要调整左边距
    if (lastRowSentence.mergedText.startsWith('“') || lastRowSentence.mergedText.startsWith('"')) {
      adjustLeft += lastRowSentence.fontSize * 0.5
    }
    return (
      // 左边距相等
      (this.equal(lastRowSentence.left, cur.left, 1.5) || this.equal(adjustLeft, cur.left, 1.5)) &&
      // 上一行的右边距大于等于下一行的右边距
      (lastRowSentence.width >= cur.width ||
        // 上一行的右边距小于下一行的右边距，但相差不大。一般会出现在单词自动换行的场景
        this.equal(lastRowSentence.right, cur.right, cur.fontSize * 4))
    )
  }

  /** 两行合并：第一行左缩进 */
  private isIndentSentence(pre: Paragraph, cur: RowSentence) {
    const lastRowSentence = pre.rowSentences[pre.rowSentences.length - 1]
    return (
      lastRowSentence.left > cur.left &&
      lastRowSentence.left - cur.left >= pre.fontSize * 0.5 &&
      this.equal(lastRowSentence.left, cur.left, cur.fontSize * 4) &&
      // 上一行的右边距大于等于下一行的右边距
      (lastRowSentence.width >= cur.width ||
        // 上一行的右边距小于下一行的右边距，但相差不大。一般会出现在单词自动换行的场景
        this.equal(lastRowSentence.right, cur.right, cur.fontSize * 4))
    )
  }

  /**
   * 两行合并：第二行为左缩进
   * 一般出现在渲染引用的情况
   */
  private isRefererSentence(pre: Paragraph, cur: RowSentence) {
    return (
      this.startWith(pre.mergedText, ['[']) &&
      !this.isJustifyCenterSentence(pre, cur) &&
      pre.width > cur.width &&
      pre.left < cur.left &&
      this.equal(pre.left, cur.left, cur.fontSize * 4)
    )
  }

  /**
   * 两行合并：判断上下两行的宽度是否超过
   * 页面宽度的三分之一。如果都没有超过三分之一，则认为是独立的两段
   */
  private isMoreThanHalfWidth(pre: Paragraph, cur: RowSentence) {
    return pre.width / pre.pageWidth > 0.25 || cur.width / cur.pageWidth > 0.25
  }

  /** 两行合并：判断上下两行是否为居中对齐 */
  private isJustifyCenterSentence(pre: Paragraph, cur: RowSentence) {
    let preLeft = pre.left
    let preRight = pre.right
    let curLeft = cur.left
    let curRight = cur.right
    // 空格按标准字符一半宽度计算
    const preWhiteWidth = pre.width / pre.mergedText.length / 2
    const curWhiteWidth = cur.width / pre.mergedText.length / 2
    const preStartWhiteSpace = pre.mergedText.match(/^\s+/)
    const preEndWhiteSpace = pre.mergedText.match(/\s+$/)
    const curStartWhiteSpace = cur.mergedText.match(/^\s+/)
    const curEndWhiteSpace = cur.mergedText.match(/\s+$/)
    if (preStartWhiteSpace) {
      preLeft += preStartWhiteSpace[0].length * preWhiteWidth
    }
    if (preEndWhiteSpace) {
      preRight -= preEndWhiteSpace[0].length * preWhiteWidth
    }
    if (curStartWhiteSpace) {
      curLeft += curStartWhiteSpace[0].length * curWhiteWidth
    }
    if (curEndWhiteSpace) {
      curRight -= curEndWhiteSpace[0].length * curWhiteWidth
    }
    const leftDiff = Math.abs(preLeft - curLeft)
    const rightDiff = Math.abs(preRight - curRight)
    const gap = 4
    // if (pre.mergedText.includes('INTRODUCTION: MOMENTS OF')) {
    //   console.log('isJustifyCenterSentence', pre.mergedText, cur.mergedText, leftDiff, rightDiff, pre.left - cur.left, pre.right - cur.right)
    // }
    return (
      pre.width > cur.width &&
      1 < leftDiff &&
      this.equal(leftDiff, rightDiff, gap) &&
      pre.fontFamily === cur.fontFamily
    )
  }

  /** 判断两个段落的基础样式是否一致 */
  private isSameComputedStyle(pre: Paragraph | RowSentence, cur: OriginalSentence | RowSentence) {
    return pre.fontSize === cur.fontSize
  }

  /** 移除掉透明度为0的元素 */
  private removeTransparentEl(el: HTMLElement) {
    Array.from(el.children).forEach((cEl) => {
      const { color } = window.getComputedStyle(cEl)
      if (color === 'transparent' || color === 'rgba(0, 0, 0, 0)') {
        cEl.innerHTML = ''
        // cEl.remove()
        return
      }
      // 判断 cEl 是否有子元素，如果有则递归
      if (cEl.children.length) {
        this.removeTransparentEl(cEl as HTMLElement)
      }
    })
  }

  /** 将 html 数据转换为定义的数据  */
  private processHtmlElToOriginalSentence(
    sentenceElList: Array<HTMLElement>,
    {
      pageWidth,
      pageHeight,
    }: {
      pageWidth: number
      pageHeight: number
    }
  ): Array<OriginalSentence> {
    const sentenceList: Array<OriginalSentence> = []
    const newSentenceElList: Array<HTMLElement> = []
    // 从 Clip 的 el 中，尝试提取 Text
    sentenceElList.forEach((el) => {
      const { left: elLeft, bottom: elBottom, color } = window.getComputedStyle(el)
      if (color === 'transparent' || color === 'rgba(0, 0, 0, 0)') {
        return
      }
      if (el.classList.contains('c')) {
        const isFormulas = this.checkIsFormulas(el)
        if (!isFormulas) {
          Array.from(el.children).forEach((_cEl) => {
            const cEl = _cEl as HTMLElement
            this.removeTransparentEl(cEl)
            const { left, bottom } = window.getComputedStyle(cEl)
            cEl.style.left = `${parseFloat(elLeft) + parseFloat(left)}px`
            cEl.style.bottom = `${parseFloat(elBottom) + parseFloat(bottom)}px`
            el.before(cEl)
            newSentenceElList.push(cEl)
          })
          el.remove()
          return
        }
      } else {
        this.removeTransparentEl(el)
      }

      newSentenceElList.push(el)
    })
    newSentenceElList.forEach((el) => {
      const {
        left,
        bottom,
        width,
        height,
        transform,
        color,
        textShadow,
        webkitTextStrokeColor,
        webkitTextStrokeWidth,
        lineHeight,
        fontSize,
        fontFamily,
        wordSpacing,
        letterSpacing,
      } = window.getComputedStyle(el)
      const innerText = el.innerText.replace(/\n/g, '').replace(//g, ' ')
      // console.log('innerText', el, `「${el.innerText}」`, `「${innerText}」`)
      let type: SentenceType = 'text'
      if (el.classList.contains('c')) {
        const isFormulas = this.checkIsFormulas(el)
        if (isFormulas) {
          type = 'formulas'
        }
      } else {
        const isSymbol = this.checkIsSymbol(innerText)
        if (isSymbol) {
          type = 'symbol'
        }
      }
      const { a: scaleX, d: scaleY } = new DOMMatrix(transform)
      const calcLeft = parseFloat(left)
      const calcBottom = parseFloat(bottom)
      const calcWidth = parseFloat(width)
      const calcHeight = parseFloat(height)
      const calcLineHeight = parseFloat(lineHeight)
      const calcFontSize = parseFloat(fontSize)
      const scaleWidth = calcWidth * scaleX
      const scaleHeight = calcHeight * scaleY
      const scaleLineHeight = calcLineHeight * scaleY
      const scaleFontSize = calcFontSize * scaleY
      const sentence: OriginalSentence = {
        pageWidth,
        pageHeight,
        // 基于 bottom 计算 top
        top: calcBottom + scaleHeight,
        bottom: calcBottom,
        left: calcLeft,
        right: calcLeft + scaleWidth,
        width: scaleWidth,
        height: scaleHeight,
        lineHeight: scaleLineHeight,
        color,
        textShadow,
        webkitTextStrokeColor,
        webkitTextStrokeWidth,
        fontSize: scaleFontSize,
        fontFamily,
        transform,
        wordSpacing,
        letterSpacing,
        textIndent: false,
        type,
        text: innerText,
        textAlignCenter: false,
        el,
      }
      sentenceList.push(sentence)
    })
    if (!sentenceList.length) {
      return []
    }
    // 计算主色和主 fontFamily
    let mainColor = sentenceList[0].color
    let mainFontFamily = sentenceList[0].fontFamily
    const colorWeights: Array<{ color: string; weight: number }> = []
    const fontFamilyWeights: Array<{ fontFamily: string; weight: number }> = []
    sentenceList.forEach((s) => {
      let colorWeightItem = colorWeights.find((cw) => {
        return cw.color === s.color
      })
      if (!colorWeightItem) {
        colorWeightItem = {
          color: s.color,
          weight: s.text.length,
        }
        colorWeights.push(colorWeightItem)
      } else {
        colorWeightItem.weight += s.text.length
      }

      let fontFamilyWeightItem = fontFamilyWeights.find((cw) => {
        return cw.fontFamily === s.fontFamily
      })
      if (!fontFamilyWeightItem) {
        fontFamilyWeightItem = {
          fontFamily: s.fontFamily,
          weight: s.text.length,
        }
        fontFamilyWeights.push(fontFamilyWeightItem)
      } else {
        fontFamilyWeightItem.weight += s.text.length
      }
    })
    colorWeights.sort((a, b) => b.weight - a.weight)
    fontFamilyWeights.sort((a, b) => b.weight - a.weight)
    mainColor = colorWeights[0].color
    mainFontFamily = fontFamilyWeights[0].fontFamily
    // 如果一段中存在多种颜色，则用主色作为该段的颜色（字体同理）
    sentenceList.forEach((s) => {
      Array.from(s.el.children).some((_el) => {
        const el = _el as HTMLElement
        if (el.classList.toString().indexOf('fc') > -1) {
          const { color } = window.getComputedStyle(el)
          if (color !== s.color && s.text !== el.innerText) {
            s.color = mainColor
            return true
          }
        }
        return false
      })
      Array.from(s.el.children).some((_el) => {
        const el = _el as HTMLElement
        if (el.classList.toString().indexOf('ff') > -1) {
          const { fontFamily } = window.getComputedStyle(el)
          if (fontFamily !== s.fontFamily && s.text !== el.innerText) {
            s.fontFamily = mainFontFamily
            return true
          }
        }
        return false
      })
    })
    return sentenceList
  }

  private checkIsFormulas(el: HTMLElement): boolean {
    const tryConsole = (idx: number) => {
      if (el.innerText === 'Site Search and Browse Capabilities') {
        console.log('checkIsFormulas', idx)
      }
      // console.log('checkIsFormulas', idx)
    }
    const text = el.innerText
    const textLength = text.replaceAll(' ', '').length
    const hasFormulaSymbolV1 =
      /[+\\*/=()^><{}]+/.test(text) && /^[+\-*/=()^.,%><{}A-Za-z\d\s]+$/.test(text)
    if (hasFormulaSymbolV1 && textLength < 100) {
      tryConsole(1)
      return hasFormulaSymbolV1
    }
    // 文本内容长度小于 20 时，判断各子元素字号是否一致，若不一致，则认为是公式
    if (textLength < 20) {
      let fontSize: string | null = null
      const hasFormulaSymbol = Array.from(el.children).some((el) => {
        const currentFontSize = getComputedStyle(el).fontSize
        const isSameFontSize = fontSize === null || fontSize === currentFontSize
        fontSize = currentFontSize
        return isSameFontSize
      })
      if (hasFormulaSymbol) {
        tryConsole(4)
        return hasFormulaSymbol
      }
    }
    // 这里先不判断是不是公式
    // 这里包裹一层，原因是有些PDF页面上，只有一个包含类名 c 的div，这时候不需要判断是否是公式
    // if (
    //   el.previousElementSibling?.classList.contains('c') ||
    //   el.nextElementSibling?.classList.contains('c')
    // ) {
    //   // 段落中的公式，渲染时全部都是大写字母
    //   const hasFormulaSymbolV2 = Array.from(el.children).some((_el) => {
    //     const el = _el as HTMLElement
    //     const text = el.innerText.replace(/\d/g, '')
    //     return /^[A-Z]+$/.test(text)
    //   })
    //   if (hasFormulaSymbolV2) {
    //     tryConsole(2)
    //     return hasFormulaSymbolV2
    //   }
    // }
    const hasFormulaSymbolV3 = textLength < 5 && /\d\s\d/.test(text)
    if (hasFormulaSymbolV3) {
      tryConsole(3)
      return hasFormulaSymbolV3
    }
    return false
  }

  private checkIsSymbol(text: string): boolean {
    // 有一些情况下字符后面会带一些标点符号，判断时给过滤掉
    if (text.replace(/[,]/g, '').length > 1) {
      if (/^\d+）$/.test(text)) {
        return true
      }
      return false
    }
    return /[a-zA-Z0-9*⁎∗^#‡§]/.test(text)
  }

  public async translatePage(
    pageDataList: Array<Types.PageData>,
    from: string,
    to: string,
    fileId: number,
    callback: (data: Array<Types.PageData>) => void,
    shareHashId?: string
  ) {
    const { needTranslateParagraphs, needTranslateImages } =
      this.getNeedTranslateParagraphs(pageDataList)
    await Promise.all(
      needTranslateImages.map(async (img) => {
        return new Promise((resolve) => {
          const base64 = img.originalSrc

          translateService.translateImage(
            {
              base64: base64,
              fromLang: 'auto',
              toLang: userService.pdfTargetLang,
              module: 'translate_pdf',
            },
            (errMsg, imageResult) => {
              img.loading = false
              // console.log(errMsg, imageResult)
              if (!errMsg && imageResult) {
                pageDataList.forEach(({ imgs }) => {
                  const targetImg = imgs.find(({ id }) => id === img.id)
                  if (targetImg) {
                    const metaDataAndBase64 = base64.split(',')
                    // 把前面元数据data:image/xxxx;base64,移除
                    const metaData = metaDataAndBase64[0]

                    targetImg.translateResult = `${metaData},${imageResult}`
                    // p.el.src = `${metaData},${imageResult}`
                    // targetImg.imageStyleInfo.src = `${metaData},${imageResult}`
                    // console.log(`${metaData},${imageResult}`)
                  }
                })
              }
              resolve({ id: img.id, imageResult, errMsg })
            }
          )
        })
      })
    )
    needTranslateParagraphs.forEach((p) => {
      let mergedText = ''
      let formulaIndex = 0
      p.rowSentences.forEach(({ originalSentences }) => {
        let rowMergedText = ''
        originalSentences.forEach((os) => {
          const { type, text } = os
          if (type === 'text') {
            rowMergedText += text
          } else if (type === 'formulas') {
            rowMergedText += ` <b${formulaIndex}></b${formulaIndex}> `
            formulaIndex++
            p.replaceFormulaSentences.push(os)
          }
        })
        // 处理单词被分割/换行的情况
        if (mergedText.endsWith('-')) {
          mergedText += rowMergedText
        } else {
          // 如果是链接，则不加空格
          if (
            p.links.some((l) => {
              return (
                !mergedText ||
                (!mergedText.includes(l) &&
                  !rowMergedText.includes(l) &&
                  (mergedText + rowMergedText).includes(l))
              )
            })
          ) {
            mergedText += rowMergedText
          } else {
            mergedText += ` ${rowMergedText}`
          }
          // mergedText += mergedText ? ` ${rowMergedText}` : rowMergedText
        }
      })
      p.mergedText = mergedText.trim().replace(/\n/g, '')
      if (/<b\d+><\/b\d+>$/.test(p.mergedText)) {
        // 如果最后一个字符是标签，加个点。不然翻译结果可能会缺一个标签
        mergedText = `${mergedText}.`
      }
      p.mergedText = mergedText
    })
    const paragraphs = needTranslateParagraphs.map(({ id, mergedText, links }) => {
      return {
        text: mergedText,
        from,
        to,
        id,
        htmlStr: '',
        links,
      }
    })
    await translateService.translate(
      {
        paragraphs,
        taskUid: this.taskUid,
        originUrl: window.location.href,
        originTitle: document.title,
        triggerType: 'pdf',
        shareHashId,
        fileId,
      },
      (results) => {
        results.forEach(({ result, id, error }) => {
          pageDataList.forEach(({ paragraphs }) => {
            const p = paragraphs.find(({ id: pId }) => pId === id)
            if (p) {
              if (!error) {
                let translateResult = result
                p.replaceFormulaSentences.forEach((os, idx) => {
                  translateResult = translateResult.replace(`<b${idx}></b${idx}>`, '[latex]')
                })
                p.translateResult = translateResult
              } else {
                p.errMsg = error || '翻译失败，请稍后重试或联系客服'
              }
            }
          })
        })
        callback(pageDataList)
      }
    )
  }

  private getNeedTranslateParagraphs(pageDataList: Array<Types.PageData>) {
    const needTranslateParagraphs: Array<Types.Paragraph> = []
    const needTranslateImages: Array<Types.ImgData> = []
    pageDataList.forEach(({ paragraphs, imgs }) => {
      paragraphs
        .filter((p) => {
          return !p.translateResult
        })
        .forEach((p) => {
          needTranslateParagraphs.push(p)
        })

      if (paragraphs.length < 4) {
        imgs.forEach((image) => {
          needTranslateImages.push(image)
        })
      }

      // imgs
      //   .filter((p) => {
      //     return paragraphs.length<4
      //   })
      //   .forEach((p) => {
      //     needTranslateImages.push(p)
      //   })
    })
    return { needTranslateParagraphs, needTranslateImages }
  }

  private isSkipTranslate(str: string) {
    return str.length <= 1 || !!(!str || /^[\d\\.:%\\(\\),%\s\\-]+$/.test(str))
  }

  private skipRender(str: string) {
    return !!(!str || /^_{6,}$/.test(str))
  }

  private equal(a: number, b: number, cap = 5) {
    return Math.abs(a - b) <= cap
  }

  private startWith(str: string, prefixList: string[]) {
    return prefixList.some((prefix) => {
      return str.startsWith(prefix)
    })
  }

  private recordTranslateStatusDebounceCall = debounce(
    async (
      params: {
        fileId: number
        fromLang: TranslateServiceType.LocaleWithAutoDetect
        toLang: TranslateServiceType.Locale
        /** 已翻译页数 */
        pageNumber: number
        /** 文件总页数 */
        totalPageNumber: number
      },
      callback: () => void
    ) => {
      await api.pdf.recordTranslateStatus(params)
      callback()
    },
    1000
  )

  private updateTaskToStorage(task: TranslateTask) {
    let { pdfTranslateTaskList } = storageHelper.get(['pdfTranslateTaskList'])
    if (!pdfTranslateTaskList) {
      pdfTranslateTaskList = []
    }
    pdfTranslateTaskList = pdfTranslateTaskList.filter(({ fileId }) => fileId !== task.fileId)
    pdfTranslateTaskList.push(task)
    storageHelper.set({ pdfTranslateTaskList })
  }
}

export const pdfTranslateHelper = new PdfTranslateHelper()
