import { commonUtils } from '../commonUtils'
import { get, post } from './common'
// type ApiBaseResult<T> = Promise<ApiTypes.ApiBaseResult & T>

export namespace pricing {
  export function list(newUser?: 1) {
    return get<{
      planList: ServerDataTypes.VipPlan[]
      eduPlanList: ServerDataTypes.VipPlan[]
    }>('/vip/plan', { newUser })
  }

  export function createOrder(p: PostDataTypes.OrderCreateParams) {
    return post<{
      orderNo: string
      tradeUrl: string
    }>('/vip/order/create', {}, p)
  }

  export function pollOrderStatus(orderId: string) {
    return get<{
      /** created: 待支付     completed: 已支付     canceled: 已取消     refunded: 已退款 */
      status: 'created' | 'completed' | 'canceled' | 'refunded'
    }>('/vip/order/status', { orderId })
  }

  // 使用兑换码
  export function userExchangeCode(code: string) {
    return post<{
      code: string
      successMsg: string
    }>('/use/exchange_code', {}, { code })
  }

  export function getWelfarePlan(newUser?: 1) {
    return get<ServerDataTypes.WelfarePlanData>('/user/favorable_plan', { newUser })
  }

  // 获取付费弹窗上需要的信息
  export function getBuyVipDialogExtraData(configInfo?: string) {
    return post<{ data: ServerDataTypes.BuyVipDialogExtraData }>(
      '/buy_vip_dialog_extra_data',
      {},
      { configInfo }
    )
  }

  export function getVipGiftReceiveQr() {
    return get<{ url: string; ticket: string }>('/user/paid/gift_qr')
  }

  export function updateActivityBuried(actionType: PostDataTypes.ActivityBuriedType) {
    return post('/activity/buried', {}, { actionType })
  }

  // 获取用户匹配的活动
  export function getUserMatchActivity() {
    return get<{ dataList: ServerDataTypes.ActivityData[] }>('/user/match_activity')
  }

  // 领取优惠券
  export function receiveCoupon(couponHashId: string) {
    return post('/user/coupon/receive', {}, { couponHashId })
  }

  // 获取优惠券列表
  export function getCouponList(status: 'expired' | 'not_expired') {
    return get<{ dataList: ServerDataTypes.CouponInfo[] }>('/user/coupon/list', { status })
  }

  // 关闭运营活动
  export function closeActivity(data: {
    activityHashId: string
    showType?: ServerDataTypes.ActivityPosition
  }) {
    return post('/user/activity/close', {}, data)
  }

  // 检查是否满足教育特惠活动条件
  export function checkEnableEduActivity() {
    return get<{
      match: boolean
    }>('/user/edu_activity')
  }

  // 获取活动信息
  export function saveActivityShow(data: {
    activityHashId: string
    showType: string
    couponHashId?: string
    planId?: string
  }) {
    return post('/user/activity/show', {}, data)
  }

  export function setActivityConfigInfo(data: { hashId: string; configInfo: string }) {
    return post('/activity/config_info', {}, data)
  }

  export function getActivityOne(activityHashId: string) {
    return get<{ data: ServerDataTypes.ActivitySingle['activityData'] }>('/activity/one', {
      activityHashId,
    })
  }

  export function getShowTopTipsPlans(planList: ServerDataTypes.VipPlan[]) {
    return post<{ dataList: string[] }>(
      '/vip_plan/show',
      {},
      { planList: JSON.stringify(commonUtils.underlize(planList)) }
    )
  }
}
