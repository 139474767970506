import { theme, ThemeConfig } from 'antd'
import type { AliasToken } from 'antd/es/theme/internal'

const { getDesignToken } = theme
const defaultThemeTokenConfig: Partial<AliasToken> = {
  colorPrimary: '#6165F7',
  colorPrimaryHover: '#595DE3',
  colorText: '#333333',
}

class ThemeHelper {
  init() {
    const { colorPrimary, colorPrimaryHover, colorText } = this.themeToken
    const rootEl = document.querySelector(':root') as HTMLElement
    rootEl.style.setProperty('--color-primary', colorPrimary!)
    rootEl.style.setProperty('--color-primary-hover', colorPrimaryHover!)
    rootEl.style.setProperty('--color-text', colorText!)
  }

  get theme(): ThemeConfig {
    return {
      cssVar: true,
      token: this.themeToken,
    }
  }

  get themeToken(): Partial<AliasToken> {
    let tokenConfig = defaultThemeTokenConfig
    return getDesignToken({ token: tokenConfig })
  }
}

export const themeHelper = new ThemeHelper()
