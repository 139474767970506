import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useRef, useState } from 'react'
import style from './OperationRecordModal.module.less'
import { Modal, Spin } from 'antd'
import { EmptyBox } from '../EmptyBox'
import { api } from '@/helpers/api'
import { dayjsHelper } from '@/helpers/dayjsHelper'
import { commonUtils } from '@/helpers/commonUtils'
import { debounce } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Z_INDEX_MAX } from '@/helpers/commonConsts'

interface EventRecord {
  date: string
  list: ServerDataTypes.EventRecordInfo[]
}

const LIMIT = 20
/** OperationRecordModal 组件的 Props */
export type OperationRecordModalProps = {
  open: boolean
  onCancel: () => void
}
const OperationRecordModal: FC<OperationRecordModalProps> = observer(
  ({ open, onCancel }: OperationRecordModalProps) => {
    const [loading, setLoading] = useState(true)
    const [recordList, setRecordList] = useState<EventRecord[]>([])
    const [hasNext, setHasNext] = useState(true)
    const [curPage, setCurPage] = useState(1)
    const fetchRecordList = async () => {
      if (curPage === 1) {
        setLoading(true)
      }
      const { data } = await api.user.getEventRecordList({
        offset: (curPage - 1) * LIMIT,
        limit: LIMIT,
      })
      const list = curPage === 1 ? [] : recordList
      setHasNext(data.length >= LIMIT)
      setLoading(false)
      for (let item of data) {
        const { createdAt } = item
        const date = dayjsHelper.formatTime(createdAt * 1000, 'YYYY-MM-DD')
        const idx = list.findIndex((item) => item.date === date)
        if (idx > -1) {
          list[idx].list.push(item)
        } else {
          list.push({
            date,
            list: [item],
          })
        }
      }
      setRecordList([...list])
    }
    useEffect(() => {
      if (!open) {
        return
      }
      fetchRecordList()
    }, [curPage, open])
    useEffect(() => {
      if (!open) {
        setCurPage(1)
        return
      }
    }, [open])
    const getEventDetail = (event: ServerDataTypes.EventRecordInfo) => {
      const { eventType, properties } = event
      switch (eventType) {
        case 'pdf_upload':
          return (
            <>
              <p className={classNames(style.title)}>
                <img
                  className={classNames(style.icon)}
                  src={commonUtils.getStaticFile('pdf/record/icon__pdf-upload.png')}
                />
                <span>上传PDF</span>
              </p>
              <p className={classNames(style.properties)}>
                <img
                  className={classNames(style.icon)}
                  src={commonUtils.getStaticFile('pdf/pdf-icon.png')}
                />
                <span>{properties.fileName}</span>
              </p>
            </>
          )
        case 'pdf_delete':
          return (
            <>
              <p className={classNames(style.title)}>
                <img
                  className={classNames(style.icon)}
                  src={commonUtils.getStaticFile('pdf/record/icon__pdf-delete.png')}
                />
                <span>删除PDF</span>
              </p>
              <p className={classNames(style.properties)}>
                <img
                  className={classNames(style.icon)}
                  src={commonUtils.getStaticFile('pdf/pdf-icon.png')}
                />
                <span>{properties.fileName}</span>
              </p>
            </>
          )
        default:
          break
      }
    }
    if (!open) {
      return null
    }
    return (
      <Modal
        wrapClassName={classNames(style.operationRecordModal)}
        width='572px'
        open={open}
        centered
        title='操作记录'
        footer={null}
        zIndex={Z_INDEX_MAX}
        onCancel={() => {
          onCancel()
        }}
      >
        <InfiniteScroll
          className={classNames(style.scrollBox)}
          dataLength={recordList.length}
          hasMore={hasNext}
          next={() => {
            setCurPage(curPage + 1)
          }}
          height={500}
          loader={<Spin></Spin>}
          endMessage={
            <>{!loading && !recordList.length && <EmptyBox desc='暂无操作记录哦～'></EmptyBox>}</>
          }
        >
          {recordList.map((item, i) => {
            return (
              <div key={`${item.date}_${i}`} className={classNames(style.recordItem)}>
                <div className={classNames(style.date)}>{item.date}</div>
                {item.list.map((event, _i) => {
                  const { eventType, createdAt, decryCount } = event
                  return (
                    <>
                      <div key={`${eventType}_${_i}`} className={classNames(style.eventItem)}>
                        <p className={classNames(style.time)}>
                          {dayjsHelper.formatTime(createdAt * 1000, 'HH:mm')}
                        </p>
                        <div
                          className={classNames(
                            style.eventDetail,
                            'flex-row',
                            'align-side',
                            'justify-center'
                          )}
                        >
                          <div className={classNames(style.left)}>{getEventDetail(event)}</div>
                          {eventType === 'pdf_upload' && (
                            <span className={classNames(style.decryCount)}>消耗X{decryCount}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            )
          })}
        </InfiniteScroll>
      </Modal>
    )
  }
)

export default OperationRecordModal
