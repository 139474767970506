import { BaseVm } from '@/mobxTools'
import { PaySuccessDialogProps } from '.'

export class PaySuccessDialogVm extends BaseVm<PaySuccessDialogProps> {
  props!: PaySuccessDialogProps

  public onViewCreate() {}

  public onPropsChange() {}
}
