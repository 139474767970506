import { PAGE_LINKS } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import style from './OnlineFooter.module.less'
type TabName = 'home' | 'pdf' | 'onlineTranslation' | 'guide' | 'contact'

type LinkType = {
  label: string
  url: string
}

const LINKS_DATA: LinkType[] = [
  {
    label: '智能图片设计',
    url: 'https://aiart.chuangkit.com/matrix',
  },
  {
    label: '创造狮',
    url: 'https://chuangzaoshi.com/operate',
  },
  {
    label: '彩漩PPT',
    url: 'https://caixuan.cc/',
  },
  {
    label: '歌者AI',
    url: 'https://gezhe.com/',
  },
  {
    label: 'AI Logo生成器',
    url: 'https://www.ubrand.com/ai-logo-generator',
  },
  {
    label: '烽火研报',
    url: 'https://www.fhyanbao.com',
  },
  {
    label: '人人秀',
    url: 'https://rrx.cn/',
  },
  {
    label: '金数据',
    url: 'https://jinshuju.net/',
  },
  {
    label: '浩客',
    url: 'https://howxm.com/',
  },
  {
    label: '维格云',
    url: 'https://vika.cn/?home=1',
  },
  {
    label: '图司机',
    url: 'https://www.tusij.com/',
  },
  {
    label: '智能图表生成',
    url: 'https://dycharts.com',
  },
  {
    label: '微词云',
    url: 'https://www.weiciyun.com',
  },
  {
    label: 'iSlide PPT',
    url: 'https://www.islide.cc/',
  },
  {
    label: '有言3D视频',
    url: 'https://www.youyan3d.com',
  },
]

type OnlineFooterProps = {
  onCurrentTabClick: () => void
  showLinks?: boolean
}

export const OnlineFooter = observer((props: OnlineFooterProps) => {
  const { onCurrentTabClick, showLinks = false } = props

  const location = useLocation()
  const [showHelpBox, setShowHelpBox] = useState(false)

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setShowHelpBox(false)
    })
  }, [])

  const handleTabClick = (tab: TabName) => {
    if (tab === 'onlineTranslation') {
      if (location.pathname === PAGE_LINKS.onlineTranslation) {
        onCurrentTabClick()
        return
      }
      window.open(`${envHelper.webHost}${PAGE_LINKS.onlineTranslation}`)
    } else if (tab === 'home') {
      window.open(`${envHelper.webHost}${PAGE_LINKS.home}`)
    } else if (tab === 'guide') {
      window.open('https://lenovo.huiyiai.net/blog/')
    } else if (tab === 'contact') {
      setShowHelpBox(true)
    } else if (tab === 'pdf') {
      if (location.pathname === PAGE_LINKS.pdf) {
        onCurrentTabClick()
        return
      }
      window.open(`${envHelper.webHost}${PAGE_LINKS.pdf}`)
    }
  }

  return (
    <div className={classNames(style.footerWrap)}>
      <div className={classNames(style.onlineFooter)}>
        <div className={classNames(style.left)}>
          {envHelper.oem === 'lenovo' ? (
            <img
              src={commonUtils.getStaticFile('cdn/home-page_icon__logo.png')}
              className={classNames(style.logo)}
            />
          ) : (
            <img
              src='https://assets.weibanzhushou.com/web/pt-web/home-page_icon__logo.1b9e042059a1.png'
              className={classNames(style.logo)}
            />
          )}
          <p className={classNames(style.desc)}>
            你看到的信息 我都会译-为你提供更好用的AI翻译和更流畅的阅读体验
          </p>
          <p className={classNames(style.copyRight)}>版权所有：武汉夜莺科技有限公司</p>
          <a
            className={classNames(style.info)}
            rel='nofollow noreferrer'
            target='_blank'
            href='https://beian.miit.gov.cn/'
          >
            鄂ICP备15002531号-16
          </a>
        </div>

        <div className={classNames(style.right)}>
          <div className={classNames(style.list)}>
            <div className={classNames(style.title)}>产品</div>
            <div className={classNames(style.item)} onClick={() => handleTabClick('home')}>
              会译·对照式翻译
            </div>
            <div className={classNames(style.item)} onClick={() => handleTabClick('pdf')}>
              会译·PDF阅读器
            </div>
            <div
              className={classNames(style.item)}
              onClick={() => handleTabClick('onlineTranslation')}
            >
              会译·在线翻译
            </div>
          </div>

          <div className={classNames(style.list)}>
            <div className={classNames(style.title)}>关于</div>
            <div className={classNames(style.item)} onClick={() => handleTabClick('guide')}>
              翻译指南
            </div>
            <div
              className={classNames(style.item)}
              onClick={(e) => {
                e.stopPropagation()
                handleTabClick('contact')
              }}
            >
              联系我们
              <div className={classNames(style.helpBox, { [style.visible]: showHelpBox })}>
                <img
                  className={classNames(style.qrCode)}
                  src='https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E8%81%94%E7%B3%BB%E5%AE%A2%E6%9C%8D.126a016dd9f4.jpeg'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showLinks && (
        <div className={classNames(style.linkWrap)}>
          <p className={classNames(style.linkTitle)}>友情链接</p>
          <div className={classNames(style.links)}>
            {LINKS_DATA.map((item) => {
              return (
                <a
                  target='blank'
                  href={item.url}
                  key={item.url}
                  className={classNames(style.link)}
                  rel='nofollow'
                >
                  {item.label}
                </a>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
})
