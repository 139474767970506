import { observer } from 'mobx-react'
import { ElementType, Suspense, lazy, useEffect, useState } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import AmeSpin from './components/AmeSpin/AmeSpin'
import { PAGE_LINKS } from './helpers/commonConsts'
import { routerService } from './services/routerService'
import { userService } from './services/userService'

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<AmeSpin fullscreen={true} />}>
      <Component {...props} />
    </Suspense>
  )

const AuthRoute = observer(({ children }: any) => {
  const [canRender, setRenderFlag] = useState(false)

  useEffect(() => {
    async function checkLogin() {
      if (!userService.isLogin) {
        await userService.updateUserInfo()
        if (!userService.userData) {
          routerService.navigateTo('login', {
            redirectUrl: encodeURIComponent(window.location.href),
          })
          return
        }
      }
      setRenderFlag(true)
    }

    checkLogin()
  }, [userService.isLogin])
  if (!canRender) return null
  return children
})

const UninstallPage = Loadable(lazy(() => import('./pages/UninstallPage/UninstallPage')))
const WelcomePage = Loadable(lazy(() => import('./pages/WelcomePage/WelcomePage')))
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage/LoginPage')))
const PricingPage = Loadable(lazy(() => import('./pages/PricingPageV1/PricingPageV1')))
const EducationIncentivesPage = Loadable(
  lazy(() => import('./pages/EducationIncentivesPage/EducationIncentivesPage'))
)
const LearningModelPage = Loadable(
  lazy(() => import('./pages/LearningModelPage/LearningModelPage'))
)
const TranslationPage = Loadable(lazy(() => import('./pages/TranslationPage/TranslationPage')))
const H5SidePanel = Loadable(lazy(() => import('./pages/H5SidePanel/H5SidePanel')))

const routes: RouteObject[] = [
  {
    path: PAGE_LINKS.login,
    element: <LoginPage />,
  },
  {
    path: PAGE_LINKS.uninstall,
    element: <UninstallPage />,
  },
  {
    path: PAGE_LINKS.welcome,
    element: <WelcomePage />,
  },
  {
    path: PAGE_LINKS.translation,
    element: <TranslationPage />,
  },
  {
    path: PAGE_LINKS.pricing,
    element: <PricingPage />,
  },
  {
    path: PAGE_LINKS.education_incentives,
    element: <EducationIncentivesPage />,
  },
  {
    path: PAGE_LINKS.onlineTranslation,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.learning,
    element: (
      <AuthRoute>
        <LearningModelPage />
      </AuthRoute>
    ),
  },
  {
    path: PAGE_LINKS.sidepanel,
    element: <H5SidePanel />,
  },
  {
    path: PAGE_LINKS.home,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.oldPdf,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.h5_invite,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.h5_price,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.h5_exchange,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.install,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.invite,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.share_wx_mp,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
  {
    path: PAGE_LINKS.help,
    element: <Navigate to={PAGE_LINKS.translation} />,
  },
]

export const Router = () => {
  return useRoutes(routes)
}
