/* eslint-disable @typescript-eslint/no-explicit-any */
import * as mobx from 'mobx'
import { observer } from 'mobx-react'
import { PropsWithChildren, useEffect, useLayoutEffect, useMemo, useState } from 'react'

mobx.configure({
  enforceActions: 'never',
})

type AnyFunc = (...args: any) => any
type Constructor<T> = new () => T
type ViewFunc<TVm> = (vm: TVm) => JSX.Element | null
export type FuncComp<P> = (props: P) => JSX.Element | null

type ReadOnly<T> = {
  readonly [P in keyof T]: T[P] extends AnyFunc | undefined ? T[P] : Readonly<T[P]>
}

export type ViewProps<T> = ReadOnly<T>
export type ViewPropsWithChildren<T> = ReadOnly<PropsWithChildren<T>>

interface IViewModel {
  onPropsChange(props: any, oldProps: any): unknown
  onBeforeMount(): void
  onViewLayout(): void
  onViewCreate(): void
  onViewDestroy(): void
}

export abstract class BaseVm<Props> {
  @mobx.observable
  _forceUpdateVersion = 1
  private _viewName: string | null = null

  public get viewName() {
    if (this._viewName === null) {
      throw new Error('called before view bound')
    } else {
      return this._viewName
    }
  }
  public set viewName(value: string) {
    if (this._viewName !== null) {
      throw new Error('view name should only change once')
    }
    this._viewName = value
  }
  abstract props: Props

  /* eslint-disable @typescript-eslint/no-useless-constructor, @typescript-eslint/no-empty-function */
  constructor() {}
  public onBeforeMount(): void {}
  public onViewLayout(): void {}
  public onViewCreate(): void {}
  public onViewDestroy(): void {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onPropsChange(props: Props, oldProps?: Props): void {}
  /* eslint-enable @typescript-eslint/no-useless-constructor, @typescript-eslint/no-empty-function */

  protected forceUpdate() {
    this._forceUpdateVersion += 1
  }

  protected nextTick(callback: () => void) {
    requestAnimationFrame(() => {
      callback()
    })
  }
}

export class ComponentFactory {
  static createComponent<VmType extends BaseVm<any>>(
    viewName: string,
    Vm: Constructor<VmType>,
    viewFn: ViewFunc<VmType>
  ): FuncComp<VmType['props']> {
    const Wrapper = observer((props: any) => {
      const vm = useMemo(() => {
        const _vm = new Vm()
        _vm.viewName = viewName
        mobx.makeObservable(_vm)
        return _vm
      }, [])

      useMemo(() => {
        const oldProps = vm.props
        vm.props = props
        ;(vm as any as IViewModel).onPropsChange(props, oldProps)
      }, [vm, props])

      // const _ = vm._forceUpdateVersion

      useState(() => {
        ;(vm as any as IViewModel).onBeforeMount()
        return null
      })

      useLayoutEffect(() => {
        ;(vm as any as IViewModel).onViewLayout()

        return () => {
          ;(vm as any as IViewModel).onViewDestroy()
        }
      }, [vm])

      useEffect(() => {
        ;(vm as any as IViewModel).onViewCreate()
      }, [vm])

      return viewFn(vm)
    })

    ;(Wrapper as any).displayName = viewName

    return Wrapper
  }
}
