import { get, post } from './common'
type ApiBaseResult<T> = Promise<ApiTypes.ApiBaseResult & T>

export namespace learning {
  // 获取系统词库列表
  export function getDatabaseList(): ApiBaseResult<{
    dataList: ServerDataTypes.learning.WordDatabaseInfo[]
    selectDatabase: string
  }> {
    return get('/word/database_list')
  }

  /**
   * 获取用户词库
   * @param grasp 是否已掌握，true为已掌握，false为生词本
   */
  export function getUserWordBookList(data: {
    grasp: 0 | 1
    limit: number
    offset: number
  }): ApiBaseResult<{
    dataList: ServerDataTypes.learning.UserBookInfo[]
    totalCount: number
  }> {
    return get('/user_word_book/list', data)
  }

  // 增加用户词库
  export function addUserWordBook(data: {
    // 单词
    word: string
    // 词库名称
    databaseName: string
    // 是否掌握
    grasp?: boolean
  }) {
    return post('/user_word_book/add', {}, data)
  }

  export function addUserWordGrasp(data: {
    // 单词
    word: string
    // 词库名称
    databaseName: string
  }) {
    return post('/user_word_book/add_grasp', {}, data)
  }

  // 更新用户词库
  export function updateUserWordBook(data: {
    // 单词
    word: string
    // 词库名称
    databaseName: string
    // 是否掌握
    grasp: boolean
    // 取消生词
    cancelWord?: boolean
  }) {
    return post('/user_word_book/update', {}, data)
  }

  // 取消已掌握或者取消生词
  export function cancelUserWordBook(data: {
    // 单词
    word: string
    // 词库名称
    databaseName: string
    // 取消已掌握
    cancelGrasp?: boolean
    // 取消生词本
    cancelWord?: boolean
  }) {
    return post('/user_word_book/cancel', {}, data)
  }

  export function getDatabaseWordList(databaseName: string): ApiBaseResult<{
    wordList: ServerDataTypes.learning.WordInfo[]
  }> {
    return get('/database/word_list', { databaseName })
  }

  export function getUserStudyDays(): ApiBaseResult<{
    dataList: Array<{
      databaseName: string
      startTime: 1720592190
      studyDays: 1
    }>
  }> {
    return get('/user_study/days_data')
  }

  export function getUserStudyBeginTime(): ApiBaseResult<{
    beginTime: TimestampSec
  }> {
    return get('/user_study/begin_time')
  }

  export function queryCountInfo(): ApiBaseResult<{ graspWordCount: number; wordCount: number }> {
    return get('/user_word_book/data')
  }

  export function queryFirstFiringTime(): ApiBaseResult<{ beginTime: number | null }> {
    return get('/user_study/begin_time')
  }
}
