import { toastController } from '@/controllers/toastController'
import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
import { userService } from '@/services/userService'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import style from './PageHeader.module.less'
import { STAFF_QR } from '@/helpers/commonConsts'

interface PageHeaderProps {
  className?: string
  rightBoxVisible?: boolean
  isScrolling?: boolean
}

const PageHeader: FC<PageHeaderProps> = observer((props: PageHeaderProps) => {
  const { className, rightBoxVisible, isScrolling } = props
  const [showHelpBox, setShowHelpBox] = useState(false)

  useEffect(() => {
    document.body.addEventListener('click', () => {
      setShowHelpBox(false)
    })
  }, [])

  const handleLogin = (type: 'login' | 'register', redirectUrl?: string) => {
    userService.loginBySLBSDK()
  }
  return (
    <div className={classNames(style.pageHeader, className, { [style.hasScroll]: isScrolling })}>
      <div className={classNames(style.leftBox)}>
        <a className={classNames('flex-row')}>
          {envHelper.oem === 'lenovo' ? (
            <img
              src={commonUtils.getStaticFile('cdn/home-page_icon__logo.png')}
              className={classNames(style.logo)}
            />
          ) : (
            <img
              src='https://assets.weibanzhushou.com/web/pt-web/home-page_icon__logo.1b9e042059a1.png'
              className={classNames(style.logo)}
            />
          )}
        </a>
      </div>
      <div
        className={classNames(style.rightBox, {
          [style.hide]: !rightBoxVisible || !userService.initialed,
        })}
      >
        <div
          className={classNames(style.linkBtn, style.linkBtnHelp)}
          onClick={(e) => {
            e.stopPropagation()
            setShowHelpBox(!showHelpBox)
          }}
        >
          联系我们
          <div className={classNames(style.helpBox, { [style.visible]: showHelpBox })}>
            <img className={classNames(style.qrCode)} src={STAFF_QR} />
          </div>
        </div>
        <Link className={classNames(style.linkBtn)} to='/pricing'>
          定价
        </Link>
        {!userService.isLogin && (
          <>
            <div
              className={classNames(style.linkBtn)}
              onClick={() => {
                handleLogin('login')
              }}
            >
              登录
            </div>
            <div
              className={classNames(style.btn)}
              onClick={() => {
                handleLogin('register')
              }}
            >
              免费注册
            </div>
          </>
        )}
        {userService.isLogin && <UserInfoBox></UserInfoBox>}
      </div>
    </div>
  )
})

export const UserInfoBox = observer(() => {
  const [showJumpToSetting, setShowJumpToSetting] = useState(false)
  useEffect(() => {
    checkPDFAndExtensionOptionPageVisible()
    commonUtils.asyncDelay(500).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
    commonUtils.asyncDelay(2000).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
    commonUtils.asyncDelay(3000).then(() => {
      checkPDFAndExtensionOptionPageVisible()
    })
  }, [])

  const checkPDFAndExtensionOptionPageVisible = () => {
    const show = commonUtils.isInstalledExtension()
    setShowJumpToSetting(show)
  }
  const userInfo = userService.userData
  if (!userInfo) {
    return null
  }

  const { wxAvatar } = userInfo
  return (
    <div
      className={classNames(style.userInfoBox, {
        [style.oemLenovo]: envHelper.oem === 'lenovo',
      })}
    >
      {wxAvatar ? (
        <img className={classNames(style.avatar)} src={wxAvatar} />
      ) : (
        <img className={classNames(style.avatar)} src={commonUtils.getDefaultAvatar()} />
      )}
      <img
        className={classNames(style.iconArrow)}
        src='https://assets.weibanzhushou.com/web/pt-web/page-header_triangle-icon.9d78d402ad4f.png'
      />
      <div className={classNames(style.menuBoxWrapper)}>
        <div className={classNames(style.menuBox)}>
          {showJumpToSetting && (
            <>
              <div
                className={classNames(style.menuLi)}
                onClick={() => {
                  commonUtils.sendExtensionMessage('openOptionPage', {})
                }}
              >
                <img
                  className={classNames(style.icon)}
                  src='https://assets.weibanzhushou.com/web/pt-web/page-header_setup-center.6e0079fe237e.png'
                />
                <span className={classNames(style.text)}>设置中心</span>
              </div>
              <div className={classNames(style.line)}></div>
            </>
          )}
          <div
            className={classNames(style.menuLi)}
            onClick={async () => {
              await userService.logoutBySLBSDK()
              toastController.success('退出成功')
              await commonUtils.asyncDelay(1000)
              window.location.reload()
            }}
          >
            <img
              className={classNames(style.icon)}
              src='https://assets.weibanzhushou.com/web/pt-web/page-header_icon__logout.0d3a4d0087e0.png'
            />
            <span className={classNames(style.text)}>退出登录</span>
          </div>
        </div>
      </div>
    </div>
  )
})

export default PageHeader
