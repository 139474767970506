import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import style from './CouponPanel.module.less'
import { commonUtils } from '@/helpers/commonUtils'
import { dayjsHelper } from '@/helpers/dayjsHelper'
import { buyVipService } from '@/services/buyVipService'
import { Spin } from 'antd'

/** CouponPanel 组件的 Props */
interface CouponListBoxProps {
  allowSelectCoupon: boolean
  selectedCoupon?: ServerDataTypes.CouponInfo
  setSelectedCoupon?: (coupon: ServerDataTypes.CouponInfo) => void
}
const UNEXPIRED_BG =
  'https://assets.weibanzhushou.com/web/pt-lenovo-web/coupon/bg__coupon-unexpired.png'
const EXPIRED_BG =
  'https://assets.weibanzhushou.com/web/pt-lenovo-web/coupon/bg__coupon-expired.png'
export const CouponListBox = observer((props: CouponListBoxProps) => {
  const { allowSelectCoupon } = props
  const [curTabKey, setCurTabKey] = useState<'not_expired' | 'expired'>('not_expired')
  const [selectedCoupon, setSelectedCoupon] = useState<ServerDataTypes.CouponInfo | null>(
    props.selectedCoupon || null
  )
  const [couponList, setCouponList] = useState<ServerDataTypes.CouponInfo[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const renderCouponList = async () => {
    setLoading(true)
    const list = await buyVipService.getCouponList(curTabKey)
    setCouponList(list)
    setLoading(false)
  }
  useEffect(() => {
    // 渲染数据
    if (!curTabKey) {
      return
    }
    setCouponList([])
    renderCouponList()
  }, [curTabKey])
  return (
    <div className={classNames(style.couponListBox)}>
      <div className={classNames(style.content, { [style.withFooter]: allowSelectCoupon })}>
        <div className={classNames(style.couponTips)}>
          <p className={classNames(style.text)}>
            1、优惠券可用于购买年费会员抵扣，超低价即可解锁全部无限制翻译功能
          </p>
          <p className={classNames(style.text)}>
            2、同一订单仅可使用一张优惠券，不可与其他优惠活动同时使用
          </p>
        </div>

        <div className={classNames(style.tabBox)}>
          <div
            className={classNames(style.tabItem, { [style.active]: curTabKey === 'not_expired' })}
            onClick={() => {
              setCurTabKey('not_expired')
            }}
          >
            未过期
          </div>
          <div
            className={classNames(style.tabItem, { [style.active]: curTabKey === 'expired' })}
            onClick={() => {
              setCurTabKey('expired')
            }}
          >
            已过期
          </div>
        </div>

        <Spin spinning={loading}>
          <div className={classNames(style.couponList)}>
            {couponList.length === 0 && !loading && (
              <div className={classNames(style.empty)}>
                <img src={commonUtils.getStaticFile('buyVipDialog/empty-coupon.png')} alt='' />
                <p>暂无可用优惠券</p>
              </div>
            )}
            {couponList.map((coupon) => {
              return (
                <div
                  key={coupon.couponHashId}
                  className={classNames(style.couponItem, {
                    [style.disabled]: curTabKey === 'expired',
                  })}
                  onClick={() => {
                    if (curTabKey === 'not_expired') {
                      setSelectedCoupon(coupon)
                      props.setSelectedCoupon?.(coupon)
                    }
                  }}
                >
                  <img
                    className={classNames(style.bg)}
                    src={curTabKey === 'not_expired' ? UNEXPIRED_BG : EXPIRED_BG}
                  />
                  <div className={classNames(style.priceBox)}>
                    <p>
                      {commonUtils.parsePriceStr(coupon.amount)} <span>元</span>
                    </p>
                  </div>
                  <div className={classNames(style.couponRight)}>
                    <div className={classNames(style.couponInfo)}>
                      <p className={classNames(style.title)}>{coupon.activityName}</p>
                      <p className={classNames(style.expired)}>
                        {dayjsHelper.formatTime(coupon.expireTime * 1000, 'YYYY-MM-DD HH:mm:ss')}
                        到期
                      </p>
                    </div>
                    {curTabKey === 'not_expired' && allowSelectCoupon && (
                      <>
                        <div
                          className={classNames(style.radioBox, {
                            [style.active]: selectedCoupon?.couponHashId === coupon.couponHashId,
                          })}
                        >
                          <img
                            className={classNames(style.default)}
                            src='https://assets.weibanzhushou.com/web/pt-lenovo-web/coupon/icon__radio.png'
                          />
                          <img
                            className={classNames(style.hover)}
                            src='https://assets.weibanzhushou.com/web/pt-lenovo-web/coupon/icon__radio-hover.png'
                          />
                          <img
                            className={classNames(style.checked)}
                            src='https://assets.weibanzhushou.com/web/pt-lenovo-web/coupon/icon__radio-checked.png'
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </Spin>
      </div>
    </div>
  )
})
