import { get } from './common'
type ApiBaseResult<T> = Promise<ApiTypes.ApiBaseResult & T>

export namespace shareWxMp {
  export function getMpShare(params: { shareId: string }): ApiBaseResult<{
    data: {
      iconUrl: string
      originUrl: string
      resContent: string
      resSummarize: string
      shareId: number
      source: string
      title: string
      pageInfo: any
    }
  }> {
    return get('/summarize/mp_share', { ...params })
  }
}
