class PtProxyHelper {
  public isReady = false
  private sendEventTaskStack: {
    eventName: PT_LENOVO_EVENT_SEND_EVENT_NAME
    data: any
    resolve: (data: any) => void
  }[] = []
  private listenExtensionEventTaskStack: {
    eventName: string
    callback: (data: any) => void
  }[] = []
  private unListenExtensionEventTaskStack: {
    eventName: string
    id: string
  }[] = []
  private maxRetryTimes = 100

  public init() {
    this.checkPtProxyIsReady()
  }

  private checkPtProxyIsReady(retryTimes = 0) {
    if (retryTimes > this.maxRetryTimes) {
      this.isReady = true
      return
    }
    if (window.ptLenovoEventProxy) {
      this.sendEventTaskStack.forEach((task) => {
        window.ptLenovoEventProxy.sendEvent(task.eventName, task.data).then(task.resolve)
      })
      this.sendEventTaskStack = []
      this.listenExtensionEventTaskStack.forEach((task) => {
        window.ptLenovoEventProxy.listenExtensionEvent(task.eventName, task.callback)
      })
      this.listenExtensionEventTaskStack = []
      this.unListenExtensionEventTaskStack.forEach((task) => {
        window.ptLenovoEventProxy.unListenExtensionEvent(task.eventName, task.id)
      })
      this.unListenExtensionEventTaskStack = []
      this.isReady = true
    } else {
      setTimeout(() => {
        this.checkPtProxyIsReady(retryTimes + 1)
      }, 100)
    }
  }

  public sendEvent(eventName: PT_LENOVO_EVENT_SEND_EVENT_NAME, data: any) {
    if (this.isReady && !window.ptLenovoEventProxy) {
      return Promise.reject('ptProxy is not ready')
    }
    if (!window.ptLenovoEventProxy) {
      const promise = new Promise((resolve) => {
        this.sendEventTaskStack.push({
          eventName,
          data,
          resolve,
        })
      })
      return promise
    }
    return window.ptLenovoEventProxy.sendEvent(eventName, data)
  }
  public listenExtensionEvent(eventName: string, callback: (data: any) => void) {
    if (this.isReady && !window.ptLenovoEventProxy) {
      return Promise.reject('ptProxy is not ready')
    }
    if (!window.ptLenovoEventProxy) {
      this.listenExtensionEventTaskStack.push({
        eventName,
        callback,
      })
      return
    }
    window.ptLenovoEventProxy.listenExtensionEvent(eventName, callback)
  }
  public unListenExtensionEvent(eventName: string, id: string) {
    if (this.isReady && !window.ptLenovoEventProxy) {
      return Promise.reject('ptProxy is not ready')
    }
    if (!window.ptLenovoEventProxy) {
      this.unListenExtensionEventTaskStack.push({
        eventName,
        id,
      })
      return
    }
    window.ptLenovoEventProxy.unListenExtensionEvent(eventName, id)
  }
}

export const ptProxyHelper = new PtProxyHelper()