import { BuyVipServiceTypes } from './serviceTypes'

export const COMMENT_LIST: BuyVipServiceTypes.Comment[] = [
  {
    avatar: '',
    name: 'feng',
    starNum: 5,
    content: '很好用的一款翻译插件，对于我这样的小白用户非常友好，实时翻译速度很快。',
    date: '2024-06-13',
  },
  {
    avatar: '',
    name: '陈',
    starNum: 5,
    content: '用了一周说一下感受，首先是安装非常方便，然后翻译速度很快，准确性也很高。',
    date: '2024-06-20',
  },
  {
    avatar: '',
    name: 'van',
    starNum: 5,
    content: '宝藏插件，对我这种海淘爱好者但不精通语言的人来说帮助可太大了！',
    date: '2024-02-28',
  },
  {
    avatar: '',
    name: 'Evelyn',
    starNum: 5,
    content: '使用很丝滑，速度很快，ai翻译很准确，中英对照式翻译很适合阅读英文网站。',
    date: '2024-05-16',
  },
  {
    avatar: '',
    name: 'tuan',
    starNum: 5,
    content: '用了有一段时间了，现在体验非常丝滑，外贸狗必备，强推！！！',
    date: '2024-03-23',
  },
  {
    avatar: '',
    name: 'mon',
    starNum: 5,
    content: '看到有博主推荐，试了下，确实还不错，对照很方便，插件能在任何网页用赞赞赞！',
    date: '2024-02-23',
  },
  {
    avatar: '',
    name: 'sam',
    starNum: 5,
    content: '正巧在看技术文档过程中发现的，翻译又流畅又准确，非常值得尝试的一款产品。',
    date: '2024-03-19',
  },
  {
    avatar: '',
    name: '王',
    starNum: 5,
    content: 'AI翻译的没有那么死板，跟其他软件对比了下，对照翻译真的很适合阅读英文网站',
    date: '2023-11-23',
  },
  {
    avatar: '',
    name: 'cc',
    starNum: 5,
    content: '已经使用好几天了发现真挺好用！！看外文网站比浏览器自带的翻译准确多了。',
    date: '2024-06-13',
  },
  {
    avatar: '',
    name: 'liu',
    starNum: 5,
    content: '用了几天发现这款插件还支持多种语言之间的翻译，覆盖面非常广，几乎可以满足我的要求。',
    date: '2024-06-20',
  },
]

export const BUY_RECORD_LIST = [
  '1分钟前 X***M，购买了月费会员',
  '2分钟前 A***L，购买了年费会员',
  '3分钟前 A***O，购买了年费会员',
  '4分钟前 C***g，购买了月费会员',
  '5分钟前 0***6，购买了年费会员',
  '6分钟前 8***9，购买了年费会员',
]
