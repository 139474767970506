import { post } from './common'

export namespace educationIncentives {
    export const postEducationIncentives = (params: {
        educationAttainment: string
        schoolName: string
        studentNumber: string
        phoneNumber: string
      }) => {
        return post<{
            success: boolean
            statusCode: number
            statusMessage: string
          }>('/user/education_info', {}, { ...params })
      
      }
}