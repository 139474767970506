import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import style from './CouponModal.module.less'
import { Modal } from 'antd'
import { CouponListBox } from '../CouponPanel'
import { Z_INDEX_MAX } from '@/helpers/commonConsts'

/** CouponModal 组件的 Props */
export type CouponModalProps = {
  open: boolean
  selectedCoupon?: ServerDataTypes.CouponInfo
  onClose?: () => void
  onOk?: (selectedCoupon: ServerDataTypes.CouponInfo) => void
}

export const CouponModal = observer((props: CouponModalProps) => {
  const { open, onClose, onOk } = props
  const [selectedCoupon, setSelectedCoupon] = useState<ServerDataTypes.CouponInfo>()
  useEffect(() => {
    setSelectedCoupon(props.selectedCoupon)
  }, [props.selectedCoupon])
  if (!open || !selectedCoupon) {
    return null
  }
  return (
    <Modal
      zIndex={Z_INDEX_MAX}
      maskClosable={false}
      open={open}
      centered
      wrapClassName={classNames(style.couponModal)}
      width='574px'
      height='566px'
      title='优惠券'
      getContainer={false}
      okText='确定'
      cancelText='取消'
      okButtonProps={{
        className: style.okButton,
      }}
      cancelButtonProps={{
        className: style.cancelButton,
      }}
      onCancel={() => {
        onClose?.()
      }}
      onOk={() => {
        onOk?.(selectedCoupon)
        onClose?.()
      }}
    >
      <CouponListBox
        allowSelectCoupon={true}
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={(coupon) => {
          setSelectedCoupon(coupon)
        }}
      />
    </Modal>
  )
})
