import { Z_INDEX_MAX } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import { ComponentFactory } from '@/mobxTools'
import { Modal, QRCode, Spin } from 'antd'
import classNames from 'classnames'
import style from './PayQrDialog.module.less'
import { PayQrDialogVm } from './PayQrDialogVm'
import { SUBSCRIPTION_TYPE } from '@/pages/PricingPageV1'

/** PayQrDialog 组件的 Props */
export type PayQrDialogProps = {
  open: boolean
  plan?: ServerDataTypes.VipPlan
  source?: string
  planTitle?: string
  planDesc?: string
  paySuccess?: () => void
  onClose?: () => void
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const PayQrDialog = ComponentFactory.createComponent('PayQrDialog', PayQrDialogVm, (vm) => {
  const { open, onClose, plan } = vm.props
  if (!plan) {
    return null
  }
  return (
    <Modal
      width='516px'
      zIndex={Z_INDEX_MAX}
      getContainer={false}
      maskClosable={false}
      wrapClassName={classNames(style.payQrDialog)}
      open={open}
      centered
      footer={false}
      onCancel={() => {
        onClose?.()
      }}
    >
      <div className={classNames(style.payQrDialogContent)}>
        <p className={classNames(style.title)}>
          {SUBSCRIPTION_TYPE.includes(plan.timeType) ? '扫码订阅' : '扫码支付'}
        </p>

        <p className={classNames(style.planTitle)}>{vm.planTitle}</p>
        <div className={classNames(style.qrBox)}>
          <img
            src={commonUtils.getStaticFile('buyVipDialog/bg__qr.png')}
            className={classNames(style.qrBg)}
          />

          {vm.qrErr && (
            <div
              className={classNames(style.reloadBox)}
              onClick={() => {
                vm.genOrderQrCode()
              }}
            >
              <img
                className={classNames(style.icon)}
                src='https://assets.weibanzhushou.com/web/pt-web/icon__qr-reload.b68e5cd3c901.png'
              />
              <span>刷新</span>
            </div>
          )}
          {!vm.qrErr && (
            <Spin spinning={!vm.qrUrl} size='small'>
              {vm.qrUrl && (
                <QRCode
                  value={vm.qrUrl}
                  size={194}
                  bordered={false}
                  style={{ padding: 4 }}
                ></QRCode>
              )}
            </Spin>
          )}
        </div>
        <p className={classNames(style.payMethod)}>
          使用
          {!vm.isSubscription && (
            <img
              className={classNames(style.wxPay)}
              src={commonUtils.getStaticFile('buyVipDialog/icon__wx-pay.png')}
            />
          )}
          <img
            className={classNames(style.alipay)}
            src={commonUtils.getStaticFile('buyVipDialog/icon__alipay.png')}
          />
          扫码支付
        </p>
        <p className={classNames(style.invoiceBox)}>
          <img
            className={classNames(style.icon)}
            src={commonUtils.getStaticFile('common/success-gray.png')}
          />
          支付完成后可开发票
        </p>
      </div>
    </Modal>
  )
})
