import { STAFF_QR, Z_INDEX_MAX } from '@/helpers/commonConsts'
import { ComponentFactory } from '@/mobxTools'
import { Modal, Spin } from 'antd'
import classNames from 'classnames'
import style from './PaySuccessDialog.module.less'
import { PaySuccessDialogVm } from './PaySuccessDialogVm'
import { commonUtils } from '@/helpers/commonUtils'

/** PaySuccessDialog 组件的 Props */
export type PaySuccessDialogProps = {
  open: boolean
  btnText?: string
  onClose?: () => void
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const PaySuccessDialog = ComponentFactory.createComponent(
  'PaySuccessDialog',
  PaySuccessDialogVm,
  (vm) => {
    const { open, onClose } = vm.props
    if (!open) {
      return null
    }
    return (
      <Modal
        open={open}
        zIndex={Z_INDEX_MAX}
        maskClosable={false}
        centered
        width={516}
        footer={false}
        getContainer={false}
        wrapClassName={style.paySuccessDialog}
        onCancel={() => {
          if (onClose) {
            onClose()
          }
        }}
      >
        <div className={classNames(style.paySuccessDialogContent)}>
          <p className={classNames(style.title)}>支付成功</p>
          <img
            className={classNames(style.successIcon)}
            src={commonUtils.getStaticFile('buyVipDialog/pay-success-banner.png')}
          />
          <div className={classNames(style.btnBox, style.flexRow)}>
            <div className={classNames(style.giftReceiveBtn)}>
              联系客服开发票
              <div className={classNames(style.giftQrBox)}>
                <p className={classNames(style.qrT)}>
                  <img
                    className={classNames(style.icon)}
                    src={commonUtils.getStaticFile('buyVipDialog/icon__wechat.png')}
                  />
                  微信扫码添加客服开发票
                </p>
                <div className={classNames(style.qrWithBg)}>
                  <img
                    src={commonUtils.getStaticFile('buyVipDialog/bg__qr.png')}
                    className={classNames(style.qrBg)}
                  />
                  <img className={classNames(style.qrCode)} src={STAFF_QR} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
)
