import { commonUtils } from '@/helpers/commonUtils'
import { ComponentFactory } from '@/mobxTools'
import classNames from 'classnames'
import style from './EmptyBox.module.less'
import { EmptyBoxVm } from './EmptyBoxVm'

/** EmptyBox 组件的 Props */
export type EmptyBoxProps = {
  desc: string
  fullscreen?: boolean
  className?: string
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
export const EmptyBox = ComponentFactory.createComponent('EmptyBox', EmptyBoxVm, (vm) => {
  const { desc, className, fullscreen = true } = vm.props
  return (
    <div className={classNames(style.emptyBox, { [style.fullscreen]: fullscreen }, className)}>
      <img className={classNames(style.icon)} src={commonUtils.getStaticFile('icon__empty.png')} />
      <p className={classNames(style.text)}>{desc}</p>
    </div>
  )
})
