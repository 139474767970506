import { get } from './common'


export namespace invite {
  export const h5UserInfo = (inviteCode: string) => {
    return get<{ userInfo: { wxAvatar: string, wxNickname: string } }>('/activity/invite/user/info', { inviteCode })
  }

  export const getWxSignData = (url: string, appid?: string) => {
    return get<{
      signData: {
        appId: string
        nonceStr: string
        timestamp: number
        signature: string
      }
    }>('/webapi/sign_wx_jssdk', { url: encodeURIComponent(url), appid: appid ?? '' })
  }


  export function list(p: { page: number, pageSize: number }) {
    return get<{
      records: {
        nickname: string, avatar: string, createdAt: TimestampSec, status: 'un-register' | 'register',
        reward: boolean
      }[]
      totalCount: number
    }>('/activity/invite/record', p)
  }


  export function info() {
    return get<{
      info: {
        /** 获取的免费时长 */
        awardTimes: number
        inviteCode: string
        /** 成功邀请人数 */
        inviteCount: number
        inviteId: number
        inviteLink: string
        wxAvatar: string
        wxNickname: string
      }
    }>('/activity/invite/info')
  }
}
