import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import VConsole from 'vconsole'
import { commonUtils } from './helpers/commonUtils'

if (
  (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'dev') &&
  commonUtils.getIsMobile()
) {
  new VConsole()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)
