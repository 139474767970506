import { get, post } from './common'
type ApiBaseResult<T> = Promise<ApiTypes.ApiBaseResult & T>

export namespace login {
  // 获取登录二维码
  export function getLoginQrCode(params: {
    utm?: string
    kword?: string
    refererChannel?: string
    refererChannelDetail?: string
    browserType?: BrowserInfo['browser']
    inviteCode?: string
    bdVid?: string
    bdUrl?: string
    qhclickid?: string
    registerChannel?: string
  }): ApiBaseResult<{
    url: string
    sceneId: string
    ticket: string
  }> {
    // 优先取 utm 作为 registerChannel
    return get('/auth/register_qr', {
      ...params,
      registerChannel: params.utm || params.registerChannel,
    })
  }

  // 检查登录状态
  export function checkLoginStatus(
    sceneId: string,
    /**
     * 用于告知后端不将登录态设置在浏览器中
     * PC 邀请流程中未登录用户引导扫码关注公众号，
     * 但是不进行登录，避免印象现有的登录流程，现有流程要求用户必须安装插件
     */
    notLogin?: boolean
  ): ApiBaseResult<{ status: 'no_scan' | 'success'; inviteAward?: boolean }> {
    return get('/auth/register_qr/check', { sceneId, notLogin: notLogin })
  }

  // 注册手机号
  export function registerPhoneNumber(phoneNum: string): ApiBaseResult<{}> {
    return post('/add_phone', {}, { phoneNum })
  }
}
