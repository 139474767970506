import { api } from '@/helpers/api'
import { Z_INDEX_MAX } from '@/helpers/commonConsts'
import { Modal } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react'
// import Input from 'rc-input'
import React, { FC, useEffect, useRef, useState } from 'react'
import style from './ExchangeCodeDialog.module.less'

/** ExchangeCodeDialog 组件的 Props */
export type ExchangeCodeDialogProps = {
  open: boolean
  onClose?: () => void
}

let lock = false
const ExchangeCodeDialog: FC<ExchangeCodeDialogProps> = observer(
  ({ onClose, open }: ExchangeCodeDialogProps) => {
    const [exchangeSuccess, setExchangeSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [code, setCode] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [messageList, setMessageList] = useState<string[]>([])
    const inputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
      if (!open) {
        return
      }
      inputRef.current?.focus()
    }, [open])
    return (
      <Modal
        open={open}
        zIndex={Z_INDEX_MAX}
        maskClosable={false}
        centered
        footer={false}
        width={exchangeSuccess ? 386 : 550}
        wrapClassName={style.exchangeCodeDialog}
        onCancel={() => {
          onClose && onClose()
        }}
      >
        {!exchangeSuccess && (
          <section
            className={classNames(style.exchangeCouponDialogContent)}
            style={{ height: '250px' }}
          >
            <div className={classNames(style.title)}>兑换码</div>
            <div className={classNames(style.inputBox, { [style.error]: isError })}>
              <input
                ref={inputRef}
                type='text'
                autoFocus
                placeholder='请在此输入兑换码'
                onChange={(e) => {
                  setCode(e.target.value)
                  setIsError(false)
                }}
              />
            </div>
            {isError && (
              <p className={classNames(style.errorTips, style.mt8)}>
                * {errorMessage || '兑换码无效'}
              </p>
            )}
            <div className={classNames(style.btnBox)}>
              <button
                className={classNames(style.btn, style.cancelBtn)}
                onClick={() => {
                  onClose && onClose()
                }}
              >
                取消
              </button>
              <button
                className={classNames(style.primaryBtn, style.btn, style.ml16)}
                onClick={() => {
                  if (!code) {
                    setIsError(true)
                    return
                  }
                  if (lock) {
                    return
                  }
                  lock = true
                  api.pricing
                    .userExchangeCode(code!)
                    .then((res) => {
                      setExchangeSuccess(true)
                      setMessageList(res.successMsg.split(' ').filter((msg) => !!msg))
                      lock = false
                    })
                    .catch((error) => {
                      setIsError(true)
                      setErrorMessage(error.statusMessage)
                      lock = false
                    })
                }}
              >
                确定
              </button>
            </div>
          </section>
        )}
        {exchangeSuccess && (
          <section className={classNames(style.exchangeCouponDialogContent)}>
            <img
              className={classNames(style.bg)}
              src='https://assets.weibanzhushou.com/web/pt-web/exchange-coupon_bg.5e9a46aec456.png'
            />
            <div className={classNames(style.successContent)}>
              <div className={classNames(style.title, style.mt12)}>恭喜你！已经成功兑换</div>
              <img
                className={classNames(style.giftIcon, style.mt20)}
                src='https://assets.weibanzhushou.com/web/pt-web/exchange-coupon_gift-icon.394c4904b7ff.png'
              />
              <div className={classNames(style.bottomTitle)}>
                <p>
                  {messageList.map((item, i) => {
                    return (
                      <>
                        {item}
                        {i !== messageList.length - 1 && <br />}
                      </>
                    )
                  })}
                </p>
                <img src='https://assets.weibanzhushou.com/web/pt-web/exchange-coupon_title-bg.80731222a004.png' />
                <p className={classNames(style.desc)}>赶紧使用试试吧！</p>
              </div>
            </div>
          </section>
        )}
      </Modal>
    )
  }
)

export default ExchangeCodeDialog
