import { BaseVm } from '@/mobxTools'
import { observable } from 'mobx'
import { PaySuccessEducationDiaLogProps } from './PaySuccessEducationDiaLog'

export class PaySuccessEducationDiaLogVm extends BaseVm<PaySuccessEducationDiaLogProps> {
  props!: PaySuccessEducationDiaLogProps

  @observable qrCode = ''

  public onViewCreate() {}

  public onPropsChange() {
    if (!this.props.open) {
      return
    }
  }
}
