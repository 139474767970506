import { get, post } from './common'
type ApiBaseResult = ApiTypes.ApiBaseResult

type TranslateType = 'machine' | 'ai'

interface TranslateResult {
  respContentList: Array<string>
  tokenCount: number
}

export interface TranslateOptions {
  taskUid?: string
  fromLang: string
  toLang: string
  textList: Array<string>
  pageUrl?: string
  pageTitle?: string
  translateType?: TranslateType
  textType?: 'html' | 'plain'
  noReduceToken?: boolean
  // 翻译时间，单位为 ms
  translateTime?: number
  token?: string
}

export namespace OnlineTranslation {
  export function translate(
    data: TranslateOptions,
    controller?: AbortController
  ): Promise<ApiBaseResult & TranslateResult> {
    return post('/page/translate/content', {}, data, undefined, controller)
  }
}
