import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import style from './PricingPageV1.module.less'
import GlobalLayout from '@/components/GlobalLayout/GlobalLayout'
import { commonUtils } from '@/helpers/commonUtils'
import { userService } from '@/services/userService'
import { dayjsHelper } from '@/helpers/dayjsHelper'
import { buyVipService } from '@/services/buyVipService'
import { Button, Carousel, QRCode, Spin } from 'antd'
import { uiController } from '@/controllers/uiController'
import { routerService } from '@/services/routerService'
import { BannerBox } from '@/components/BannerBox'
import { BuyVipServiceTypes } from '@/services/buyVipService/serviceTypes'
import { CouponListBox } from '@/components/CouponPanel'
import { useSearchParams } from 'react-router-dom'
import { RouterServiceTypes } from '@/services/routerService/serviceTypes'
import { activityHelper } from '@/helpers/activityHelper/activityHelper'

interface PlanItem extends BuyVipServiceTypes.PlanData {
  planTitle: string
  priceStr: string
  isSubscription: boolean
}

/**
 * @author TODO
 * (在这里对组件进行说明)
 */
const getStaticFile = (path: string) => {
  return commonUtils.getStaticFile(`buyVipDialog/${path}`)
}
export const SUBSCRIPTION_TYPE = ['year_subscription', 'quarter_subscription', 'month_subscription']
const TRANSLATE_FUNC_LIST = [
  '网页翻译',
  '划词翻译',
  '悬停翻译',
  '在线翻译',
  '搜索翻译',
  '视频翻译',
  '图片翻译',
  '截图翻译',
]
const LEARNING_FUNC_LIST = ['单词高亮', '生词本', '学习回顾']
const LEARNING_DATABASE_LIST = ['四级', '六级', '考研英语', '雅思', '托福', 'GRE', 'SAT']

const getPlanTitle = (timeType: ServerDataTypes.VipTimeType) => {
  switch (timeType) {
    case 'month':
      return '月费会员'
    case 'quarter_subscription':
      return '连续包季首季'
    case 'month_subscription':
      return '连续包月首月'
    case 'year':
      return '年费会员'
  }
  return ''
}

let sessionId: string = ''
let timer: any
const clearOrderCreateTimeout = () => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
  sessionId = ''
}
const DEFAULT_QR =
  'https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E8%81%94%E7%B3%BB%E5%AE%A2%E6%9C%8D.126a016dd9f4.jpeg'

const PricingPageV1 = observer(() => {
  const [params] = useSearchParams()
  const queryParam = Array.from(params.entries()).reduce((prev, cur) => {
    const key = cur[0]
    const value = cur[1]
    return { ...prev, ...{ [key]: value } }
  }, {} as RouterServiceTypes.PageParams['pricing'])
  const [planList, setPlanList] = useState<PlanItem[]>([])
  const [selectedPlan, setSelectedPlan] = useState<PlanItem | null>(null)
  const [orderCreating, setOrderCreating] = useState(false)
  const [qrErr, setQrErr] = useState(false)
  const [orderQrCode, setOrderQrCode] = useState('')
  const [activity, setActivity] = useState<ServerDataTypes.ActivityData>()
  const [selectedCoupon, setSelectedCoupon] = useState<ServerDataTypes.CouponInfo | null>(null)
  const [listFetching, setListFetching] = useState(true)
  const [couponFetching, setCouponFetching] = useState(true)
  const [commentList, setCommentList] = useState<BuyVipServiceTypes.Comment[]>([])
  const [buyRecordList, setBuyRecordList] = useState<string[]>([])
  const [curPanel, setCurPanel] = useState<'coupon' | 'pay'>(
    queryParam.type === 'coupon' ? 'coupon' : 'pay'
  )
  const [showTopTipsPlanIds, setShowTopTipsPlanIds] = useState<string[]>([])
  const [descTab, setDescTab] = useState<'func' | 'benefit'>('benefit')
  const hasCoupon = useCallback(() => {
    if (!selectedPlan || !selectedCoupon) {
      return false
    }
    return selectedPlan.timeType === 'year' && !selectedPlan.isDiscount && !!selectedCoupon
  }, [selectedCoupon, selectedPlan])

  useEffect(() => {
    buyVipService.getBuyVipDialogExtraData().then(({ commentList, buyRecordList }) => {
      setCommentList(commentList)
      setBuyRecordList(buyRecordList)
    })

    userService.postLenovoRecord({
      carrier: '定价页',
      module: '定价页',
      function: '定价页',
      position: '定价页',
      action: '展现',
      element: '页面',
    })
    return () => {
      if (userService.isLogin) {
        buyVipService.updateActivityBuried('click_paid_popup')
      }
    }
  }, [])
  const initPlansData = useCallback(async () => {
    setListFetching(true)
    const { planList, matchActivity } = await buyVipService.list()
    let activePlan: { icon?: string; planId?: string } = {}
    if (matchActivity) {
      const { activityConfig } = activityHelper.getCurActivityConfigInfo(matchActivity)
      activePlan = activityConfig?.activePlan || {}
    }
    const _planList: PlanItem[] = planList.map((plan) => {
      return {
        ...plan,
        planTitle: getPlanTitle(plan.timeType),
        priceStr: (plan.price / 100).toString(),
        isSubscription: SUBSCRIPTION_TYPE.includes(plan.timeType),
      }
    })
    const plan =
      _planList.find((item) => item.isDiscount) ||
      _planList.find((item) => selectedCoupon && item.timeType === 'year') ||
      _planList.find((item) => item.id === activePlan.planId) ||
      _planList.find((item) => {
        return item.trigger
      }) ||
      _planList.find((item) => item.timeType === 'year') ||
      _planList[0]
    setSelectedPlan(plan)
    setPlanList(_planList)
    setActivity(matchActivity || undefined)
    buyVipService
      .getShowTopTipsPlans(
        _planList.map((p) => {
          return {
            ...p,
            actTrigger: p.id === plan.id,
            activity: p.isDiscount,
            useCoupon: selectedCoupon && plan.timeType === 'year',
          }
        })
      )
      .then((ids) => {
        setShowTopTipsPlanIds(ids)
      })
    setListFetching(false)
  }, [selectedCoupon])
  useEffect(() => {
    if (!couponFetching) {
      initPlansData()
    }
  }, [couponFetching])
  const initCouponData = async () => {
    setCouponFetching(true)
    const couponList = await buyVipService.getCouponList('not_expired')
    couponList.sort((a, b) => {
      return a.amount - b.amount
    })
    if (couponList.length > 0) {
      setSelectedCoupon(couponList[0])
    }
    setCouponFetching(false)
  }
  useEffect(() => {
    if (!curPanel) {
      return
    }
    if (curPanel !== 'pay') {
      clearOrderCreateTimeout()
      return
    }
    console.log('curPanel', curPanel, buyVipService.triggerUpdatePlanList, curPanel)
    initCouponData()
  }, [userService.isLogin, buyVipService.triggerUpdatePlanList, curPanel])

  useEffect(() => {
    if (!userService.isLogin) {
      setOrderQrCode(DEFAULT_QR)
      setOrderCreating(false)
    }
    if (!selectedPlan || !userService.isLogin || couponFetching || listFetching) {
      return
    }
    genOrderQrCode()
  }, [
    selectedPlan,
    userService.isLogin,
    couponFetching,
    listFetching,
    selectedCoupon?.couponHashId,
  ])

  useEffect(() => {
    if (descTab === 'benefit' && userService.enableEduActivity) {
      userService.postLenovoRecord({
        carrier: '定价页',
        module: '运营活动',
        function: '教育特惠',
        position: '付费弹窗-教育特惠',
        action: '展现',
        element: '按钮',
      })
    }
  }, [descTab, userService.enableEduActivity])

  // 轮询支付状态
  const pollingOrderStatus = async (orderId: string, force = false, cnt = 0) => {
    if (!userService.isLogin) {
      return
    }
    if (force) {
      try {
        const { status } = await buyVipService.pollOrderStatus(orderId)
        if (!sessionId) {
          return
        }
        clearOrderCreateTimeout()
        if (status === 'completed') {
          try {
            commonUtils.sendExtensionMessage('markPaid', {})
          } catch (error) {}
          /** 停止轮询,给出成功提示 */
          uiController.paySuccessDialogProps = {
            open: true,
          }

          buyVipService.triggerUpdatePlanList = !buyVipService.triggerUpdatePlanList
          return
        }
        if (status === 'canceled' || status === 'refunded') {
          /** 停止轮询 */
          return
        }
        if (status === 'created') {
          timer = setTimeout(() => {
            pollingOrderStatus(orderId, true, 0)
          }, 1000)
        }
      } catch (error) {
        if (cnt > 5) {
          clearOrderCreateTimeout()
          setQrErr(true)
          /** 重试结束，停止轮询 */
          return
        }
        pollingOrderStatus(orderId, true, cnt + 1)
      }
    }
  }
  // 创建支付订单
  const genOrderQrCode = async () => {
    if (!userService.isLogin) {
      console.error('未找到用户登录态')
      return
    }
    console.log('创建支付订单')
    const { id } = selectedPlan!
    setOrderCreating(true)
    setQrErr(false)

    const _sessionId = commonUtils.genId()
    sessionId = _sessionId
    try {
      const data = await buyVipService.createOrder({
        action: userService.vipVersion === 'free' ? 'buy' : 'renew',
        planId: id,
        source: `pricingPage_w`,
        couponHashId: hasCoupon() ? selectedCoupon?.couponHashId : undefined,
      })
      if (_sessionId !== sessionId) {
        return
      }
      const { orderNo, tradeUrl } = data
      setOrderQrCode(tradeUrl)
      /** 取消上一次轮询 */
      clearOrderCreateTimeout()
      pollingOrderStatus(orderNo, true)
    } catch (error) {
      console.log(error)
      setQrErr(true)
    }
    setOrderCreating(false)
  }
  return (
    <GlobalLayout pageHeaderVisible hideFooter>
      <div className={classNames(style.pricingPageV1)}>
        <img className={classNames(style.bgImg)} src={getStaticFile('bg__img.png')} />
        <div className={classNames(style.content)}>
          {/* 固定运营位置 */}
          {!!activity && (
            <BannerBox
              activity={activity}
              type='pay_page_top_banner'
              styleCSS={{ marginTop: '8px', width: '910px' }}
            />
          )}
          {/* 支付面板 */}
          {curPanel === 'pay' && (
            <section className={classNames(style.buyVipContent)}>
              <div className={classNames(style.left)}>
                {/* 用户信息 */}
                <div className={classNames(style.userInfoBox)}>
                  <div className={classNames(style.userInfo)}>
                    {userService.userData?.wxAvatar ? (
                      <img src={userService.userData?.wxAvatar} />
                    ) : (
                      <img src={commonUtils.getDefaultAvatar()} />
                    )}
                    {userService.isLogin ? (
                      <div>
                        <div className={classNames(style.userName)}>
                          {userService.userData?.nickname
                            ? userService.userData?.nickname
                            : `${userService.userId.toUpperCase()}`}
                          {userService.isVip && (
                            <img
                              className={classNames(style.vipIcon)}
                              src={getStaticFile('icon__vip-v.png')}
                            />
                          )}
                        </div>
                        {userService.isVip && (
                          <div className={classNames(style.userExpired)}>
                            到期时间：
                            {dayjsHelper.formatTime(userService.vipExpiredTime * 1000)}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={classNames(style.userName)}>
                        未登录
                        <Button
                          className={classNames(style.loginBtn)}
                          style={{ marginLeft: '12px' }}
                          onClick={() => {
                            userService.loginBySLBSDK()
                          }}
                        >
                          立即登录
                        </Button>
                      </div>
                    )}
                  </div>
                  {userService.isLogin && (
                    <Button
                      className={classNames(style.couponBtn)}
                      onClick={() => {
                        setCurPanel('coupon')
                      }}
                    >
                      <img
                        className={classNames(style.icon)}
                        src={getStaticFile('icon__code.png')}
                      />
                      优惠券
                    </Button>
                  )}
                </div>
                {/* 用户购买记录滚动展示 */}
                <div className={classNames(style.buyRecordBox)}>
                  <Carousel dotPosition='left' dots={false} autoplay>
                    {buyRecordList.map((item) => {
                      return (
                        <div key={item} className={classNames(style.recordItem)}>
                          <div>
                            <img src={getStaticFile('icon__buy-record.png')} />
                            {item}
                          </div>
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
                {/* 套餐列表 */}
                <Spin spinning={planList.length === 0} style={{ height: '143px' }}>
                  <div className={classNames(style.planList)}>
                    {planList.map((plan) => {
                      return (
                        <div
                          key={plan.id}
                          className={classNames(style.planItemBox, {
                            [style.active]: selectedPlan?.id === plan.id,
                          })}
                          onClick={() => {
                            if (selectedPlan?.id !== plan.id) {
                              setSelectedPlan(plan)
                            }
                          }}
                        >
                          <div className={classNames(style.planItem)}>
                            {!!plan.topTips && showTopTipsPlanIds.includes(plan.id) && (
                              <div className={classNames(style.recommendBox)}>{plan.topTips}</div>
                            )}
                            <div className={classNames(style.planTop)}>
                              <p className={classNames(style.planTitle)}>{plan.planTitle}</p>
                              <div className={classNames(style.priceBox)}>
                                ¥<span>{plan.price / 100}</span>
                              </div>
                            </div>
                            {!!plan.bottomTips && (
                              <>
                                <div className={classNames(style.preMonthPrice)}>
                                  {plan.bottomTips}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Spin>
                <p
                  className={classNames(style.subscriptionTips, {
                    [style.hidden]: !selectedPlan?.isSubscription,
                  })}
                >
                  首{selectedPlan?.timeType === 'month_subscription' ? '月' : '季'}到期后
                  {selectedPlan?.originPrice && selectedPlan?.originPrice / 100}
                  元/{selectedPlan?.timeType === 'month_subscription' ? '月' : '季'}
                  ，可随时去支付宝中关闭
                </p>
                {/* 功能列表 */}

                <div className={classNames(style.funcBox)}>
                  {/* 功能列表tab */}
                  <div className={classNames(style.funcBoxTabs)}>
                    <div
                      className={classNames(style.funcBoxTab, {
                        [style.active]: descTab === 'benefit',
                      })}
                      onClick={() => {
                        setDescTab('benefit')
                      }}
                    >
                      <img
                        className={classNames(style.tabImg)}
                        src={getStaticFile('icon__benefit-tab.png')}
                        alt=''
                      />
                      <img
                        className={classNames(style.tabActiveImg)}
                        src={getStaticFile('icon__benefit-tab-active.png')}
                        alt=''
                      />
                      <img
                        className={classNames(style.tabHoverImg)}
                        src={getStaticFile('icon__benefit-tab-hover.png')}
                      />
                      会员权益
                    </div>
                    <div
                      className={classNames(style.funcBoxTab, {
                        [style.active]: descTab === 'func',
                      })}
                      onClick={() => {
                        setDescTab('func')
                      }}
                    >
                      <img
                        className={classNames(style.tabImg)}
                        src={getStaticFile('icon__func-tab.png')}
                        alt=''
                      />
                      <img
                        className={classNames(style.tabActiveImg)}
                        src={getStaticFile('icon__func-tab-active.png')}
                        alt=''
                      />
                      <img
                        className={classNames(style.tabHoverImg)}
                        src={getStaticFile('icon__func-tab-hover.png')}
                      />
                      功能列表
                    </div>
                  </div>

                  <div
                    className={classNames(style.funcBoxContent, {
                      [style.hide]: descTab === 'func',
                    })}
                  >
                    <div className={classNames(style.funcItem)}>
                      <img src={getStaticFile('benefit-icon-1.png')} alt='' />
                      <div>
                        <p className={classNames(style.funcTitle)}>畅享无限制翻译功能</p>
                        <p className={classNames(style.funcDesc)}>
                          可使用全部翻译功能，无次数与额度限制
                        </p>
                      </div>
                    </div>
                    <div className={classNames(style.funcItem)}>
                      <img src={getStaticFile('benefit-icon-2.png')} alt='' />
                      <div>
                        <p className={classNames(style.funcTitle)}>体验精准稳定翻译服务</p>
                        <p className={classNames(style.funcDesc)}>
                          AI大语言模型结合传统翻译，提供更稳定准确的翻译结果
                        </p>
                      </div>
                    </div>
                    <div className={classNames(style.funcItem)}>
                      <img src={getStaticFile('benefit-icon-3.png')} alt='' />
                      <div>
                        <p className={classNames(style.funcTitle)}>满足浏览器全场景翻译需求</p>
                        <p className={classNames(style.funcDesc)}>
                          多种翻译功能，涵盖网页浏览、资讯获取及学术研究等场景
                        </p>
                      </div>
                    </div>
                    <div className={classNames(style.funcItem)}>
                      <img src={getStaticFile('benefit-icon-4.png')} alt='' />
                      <div>
                        <p className={classNames(style.funcTitle)}>开启学习模式助力成长</p>
                        <p className={classNames(style.funcDesc)}>
                          学习模式下浏览网页时轻松记单词，为你提升学习效率
                        </p>
                      </div>
                    </div>
                    {userService.enableEduActivity && (
                      <div className={classNames(style.eduBox)}>
                        <div
                          className={classNames(style.eduInner)}
                          onClick={() => {
                            userService.postLenovoRecord({
                              carrier: '定价页',
                              module: '运营活动',
                              function: '教育特惠',
                              position: '付费弹窗-教育特惠',
                              action: '点击',
                              element: '按钮',
                            })
                            routerService.navigateTo('education_incentives')
                          }}
                        >
                          <img
                            className={classNames(style.eduIcon)}
                            src={getStaticFile('icon__edu-guide.png')}
                          />
                          完成学生认证享「教育特惠」
                          <img
                            className={classNames(style.arrowRight, style.default)}
                            src={getStaticFile('icon__right-brown.png')}
                          />
                          <img
                            className={classNames(style.arrowRight, style.hover)}
                            src={getStaticFile('icon__right-brown-hover.png')}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames(style.funcList, style.funcBoxContent, {
                      [style.hide]: descTab === 'benefit',
                    })}
                  >
                    <div className={classNames(style.left)}>
                      <p className={classNames(style.title)}>
                        <img
                          className={classNames(style.icon)}
                          src={getStaticFile('icon__get.png')}
                        />
                        覆盖浏览器全场景翻译需求：
                      </p>
                      <ul>
                        {TRANSLATE_FUNC_LIST.map((item) => {
                          return (
                            <li key={item}>
                              <img
                                className={classNames(style.icon)}
                                src={getStaticFile('success-black.png')}
                              />
                              <span>{item}</span>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                    <div className={classNames(style.right)}>
                      <p className={classNames(style.title)}>
                        <img
                          className={classNames(style.icon)}
                          src={getStaticFile('icon__get.png')}
                        />
                        学习模式浏览器网页的同时记单词：
                      </p>
                      <ul>
                        {LEARNING_FUNC_LIST.map((item) => {
                          return (
                            <li key={item}>
                              <img
                                className={classNames(style.icon)}
                                src={getStaticFile('success-black.png')}
                              />
                              <span>{item}</span>
                            </li>
                          )
                        })}
                        <li className={classNames(style.learningLi)}>
                          <img
                            className={classNames(style.icon)}
                            src={getStaticFile('success-black.png')}
                          />
                          <div className={classNames(style.databaseList)}>
                            {LEARNING_DATABASE_LIST.map((item) => {
                              return <span key={item}>{item}</span>
                            })}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames(style.right)}>
                <img className={classNames(style.rightBg)} src={getStaticFile('right-bg.png')} />
                {/* 订单信息 */}
                <div className={classNames(style.buyBox)}>
                  <p className={classNames(style.planTitle)}>{selectedPlan?.planTitle}</p>
                  <div className={classNames(style.planPrice)}>
                    ¥
                    {commonUtils.parsePriceStr(
                      hasCoupon()
                        ? selectedPlan!.price - selectedCoupon!.amount
                        : selectedPlan?.price || 0
                    )}
                    {hasCoupon() && (
                      <div>已抵扣{commonUtils.parsePriceStr(selectedCoupon?.amount || 0)}元</div>
                    )}
                  </div>
                  <div className={classNames(style.qrBox)}>
                    <img className={classNames(style.bg)} src={getStaticFile('bg__qr.png')} />
                    {userService.isLogin &&
                      !qrErr &&
                      !orderCreating &&
                      selectedPlan?.isSubscription && (
                        <img
                          className={classNames(style.qrLogo)}
                          src={getStaticFile('qr-logo-alipay.png')}
                          alt=''
                        />
                      )}
                    {userService.isLogin &&
                      !qrErr &&
                      !orderCreating &&
                      !selectedPlan?.isSubscription && (
                        <img
                          className={classNames(style.qrLogo)}
                          src={getStaticFile('qr-logo-normal.png')}
                          alt=''
                        />
                      )}

                    <Spin spinning={orderCreating} size='small'>
                      <QRCode
                        status={orderCreating ? 'loading' : 'active'}
                        value={orderQrCode}
                        size={160}
                        bordered={false}
                        style={{ padding: 4 }}
                      ></QRCode>
                    </Spin>
                    {!userService.isLogin && (
                      <div className={classNames(style.reloadBox, style.noLoginBox)}>
                        <Button
                          className={classNames(style.loginBtn)}
                          onClick={() => {
                            userService.loginBySLBSDK()
                          }}
                        >
                          立即登录
                        </Button>
                      </div>
                    )}
                    {qrErr && (
                      <div
                        className={classNames(style.reloadBox)}
                        onClick={async () => {
                          genOrderQrCode()
                        }}
                      >
                        <img src='https://assets.weibanzhushou.com/web/pt-web/icon__qr-reload.b68e5cd3c901.png' />
                        <span className={classNames(style.text)}>刷新</span>
                      </div>
                    )}
                  </div>
                  <p className={classNames(style.payMethods)}>
                    付款方式
                    {!selectedPlan?.isSubscription && (
                      <img
                        className={classNames(style.wxPay)}
                        src={getStaticFile('icon__wx-pay.png')}
                      />
                    )}
                    <img
                      className={classNames(style.alipay)}
                      src={getStaticFile('icon__alipay.png')}
                    />
                    , 支付后可开发票
                  </p>
                  <p
                    className={classNames(style.subscriptionPdf, style.payMethods, {
                      [style.hidden]: !selectedPlan?.isSubscription,
                    })}
                  >
                    开通即表示同意
                    <a
                      href='https://assets.weibanzhushou.com/web/pt-lenovo/%E8%87%AA%E5%8A%A8%E7%BB%AD%E8%B4%B9%E6%9C%8D%E5%8A%A1%E8%A7%84%E5%88%9920240920.pdf'
                      target='_blank'
                      rel='noreferrer'
                    >
                      《自动续费协议》
                    </a>
                  </p>
                </div>
                {/* 优惠券信息 */}
                <div className={classNames(style.couponBox, { [style.enable]: hasCoupon })}>
                  {hasCoupon() && (
                    <div
                      className={classNames(style.couponInfo)}
                      onClick={() => {
                        // 点击优惠券列表
                        uiController.couponDialogProps = {
                          open: true,
                          selectedCoupon: selectedCoupon!,
                          onOk: (selectedCoupon) => {
                            setSelectedCoupon(selectedCoupon)
                          },
                        }
                      }}
                    >
                      <div className={classNames(style.left)}>
                        <img
                          className={classNames(style.icon)}
                          src={getStaticFile('icon__code.png')}
                        />
                        优惠券
                      </div>
                      <div className={classNames(style.right)}>
                        <span>-{commonUtils.parsePriceStr(selectedCoupon?.amount || 0)}元</span>
                        <img
                          className={classNames(style.arrowIcon)}
                          src={getStaticFile('icon__right-arrow.png')}
                        />
                      </div>
                    </div>
                  )}
                  {!hasCoupon() && (
                    <div className={classNames(style.left)}>
                      <img
                        className={classNames(style.icon)}
                        src={getStaticFile('icon__code-disabled.png')}
                      />
                      暂无可用优惠券
                    </div>
                  )}
                </div>
                {/* 用户评价板块 */}
                <div className={classNames(style.userCommentBox)}>
                  <img
                    className={classNames(style.textImg)}
                    src={getStaticFile('user-recommend-score.png')}
                  />
                  <div className={classNames(style.commentList)}>
                    <Carousel
                      adaptiveHeight={true}
                      speed={2000}
                      dotPosition='left'
                      dots={false}
                      autoplay
                    >
                      {commentList.map((item, i) => {
                        return (
                          <div key={i} className={classNames(style.commentItem)}>
                            <img
                              className={classNames(style.bg)}
                              src={getStaticFile('bg__comment-1.png')}
                            />
                            <div className={classNames(style.commentUser)}>
                              <span className={classNames(style.avatar)}>
                                {item.name.slice(0, 1)}
                              </span>
                              <p className={classNames(style.name)}>{item.name}</p>
                            </div>
                            <div className={classNames(style.commentContent)}>{item.content}</div>
                          </div>
                        )
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </section>
          )}
          {/* 优惠券列表页面 */}
          {curPanel === 'coupon' && (
            <section className={classNames(style.couponListBox)}>
              {/* 顶部 返回按钮 */}
              <div className={classNames(style.couponListBoxHeader)}>
                <div
                  onClick={() => {
                    setCurPanel('pay')
                  }}
                >
                  <span className={classNames(style.backBtn)}>
                    <img src={commonUtils.getStaticFile('icon__back.png')} />
                  </span>
                  返回
                </div>
              </div>
              <CouponListBox allowSelectCoupon={false} />
            </section>
          )}
        </div>
      </div>
    </GlobalLayout>
  )
})

export default PricingPageV1
