import type { TranslateServiceType } from './serviceTypes'

export namespace TranslateServiceConsts {
  export const DEFAULT_TRANSLATE_TARGET_LANG = 'zh-Hans'
  export const AUTO_DETECT_LANG = 'auto'
  export const GOOGLE_TRANSLATE_UNSUPPORTED_LANGS = ['yue', 'ba', 'lzh']
  export const FREE_TRANSLATE_API_DEFAULT_TIMEOUT_MS = 8 * 1000
  export const ALL_LOCALES: {
    [key: string]: { name: string; nativeName: string; chineseName?: string }
  } = {
    af: {
      name: 'Afrikaans',
      nativeName: 'Afrikaans',
      chineseName: '南非荷兰语',
    },
    am: {
      name: 'Amharic',
      nativeName: 'አማርኛ',
      chineseName: '阿姆哈拉语',
    },
    ar: {
      name: 'Arabic',
      nativeName: 'العربية',
      chineseName: '阿拉伯语',
    },
    as: {
      name: 'Assamese',
      nativeName: 'অসমীয়া',
      chineseName: '阿萨姆语',
    },
    az: {
      name: 'Azerbaijani',
      nativeName: 'Azərbaycan',
      chineseName: '阿塞拜疆语',
    },
    ba: {
      name: 'Bashkir',
      nativeName: 'Bashkir',
      chineseName: '巴什基尔语',
    },
    bg: {
      name: 'Bulgarian',
      nativeName: 'Български',
      chineseName: '保加利亚语',
    },
    bn: {
      name: 'Bangla',
      nativeName: 'বাংলা',
      chineseName: '孟加拉语',
    },
    bo: {
      name: 'Tibetan',
      nativeName: 'བོད་སྐད་',
      chineseName: '藏语',
    },
    bs: {
      name: 'Bosnian',
      nativeName: 'Bosnian',
      chineseName: '波斯尼亚语',
    },
    ca: {
      name: 'Catalan',
      nativeName: 'Català',
      chineseName: '加泰罗尼亚语',
    },
    cs: {
      name: 'Czech',
      nativeName: 'Čeština',
      chineseName: '捷克语',
    },
    cy: {
      name: 'Welsh',
      nativeName: 'Cymraeg',
      chineseName: '威尔士语',
    },
    da: {
      name: 'Danish',
      nativeName: 'Dansk',
      chineseName: '丹麦语',
    },
    de: {
      name: 'German',
      nativeName: 'Deutsch',
      chineseName: '德语',
    },
    dsb: {
      name: 'Lower Sorbian',
      nativeName: 'Dolnoserbšćina',
      chineseName: '下索布语',
    },
    dv: {
      name: 'Divehi',
      nativeName: 'ދިވެހިބަސް',
      chineseName: '迪维希语',
    },
    el: {
      name: 'Greek',
      nativeName: 'Ελληνικά',
      chineseName: '希腊语',
    },
    en: {
      name: 'English',
      nativeName: 'English',
      chineseName: '英语',
    },
    es: {
      name: 'Spanish',
      nativeName: 'Español',
      chineseName: '西班牙语',
    },
    et: {
      name: 'Estonian',
      nativeName: 'Eesti',
      chineseName: '爱沙尼亚语',
    },
    eu: {
      name: 'Basque',
      nativeName: 'Euskara',
      chineseName: '巴斯克语',
    },
    fa: {
      name: 'Persian',
      nativeName: 'فارسی',
      chineseName: '波斯语',
    },
    fi: {
      name: 'Finnish',
      nativeName: 'Suomi',
      chineseName: '芬兰语',
    },
    fil: {
      name: 'Filipino',
      nativeName: 'Filipino',
      chineseName: '菲律宾语',
    },
    fj: {
      name: 'Fijian',
      nativeName: 'Na Vosa Vakaviti',
      chineseName: '斐济语',
    },
    fo: {
      name: 'Faroese',
      nativeName: 'Føroyskt',
      chineseName: '法罗语',
    },
    fr: {
      name: 'French',
      nativeName: 'Français',
      chineseName: '法语',
    },
    'fr-CA': {
      name: 'French (Canada)',
      nativeName: 'Français (Canada)',
      chineseName: '法语（加拿大）',
    },
    ga: {
      name: 'Irish',
      nativeName: 'Gaeilge',
      chineseName: '爱尔兰语',
    },
    gl: {
      name: 'Galician',
      nativeName: 'Galego',
      chineseName: '加利西亚语',
    },
    gom: {
      name: 'Konkani',
      nativeName: 'Konkani',
      chineseName: '康卡尼语',
    },
    gu: {
      name: 'Gujarati',
      nativeName: 'ગુજરાતી',
      chineseName: '古吉拉特语',
    },
    ha: {
      name: 'Hausa',
      nativeName: 'Hausa',
      chineseName: '豪萨语',
    },
    he: {
      name: 'Hebrew',
      nativeName: 'עברית',
      chineseName: '希伯来语',
    },
    hi: {
      name: 'Hindi',
      nativeName: 'हिन्दी',
      chineseName: '印地语',
    },
    hr: {
      name: 'Croatian',
      nativeName: 'Hrvatski',
      chineseName: '克罗地亚语',
    },
    hsb: {
      name: 'Upper Sorbian',
      nativeName: 'Hornjoserbšćina',
      chineseName: '上索布语',
    },
    ht: {
      name: 'Haitian Creole',
      nativeName: 'Haitian Creole',
      chineseName: '海地克里奥尔语',
    },
    hu: {
      name: 'Hungarian',
      nativeName: 'Magyar',
      chineseName: '匈牙利语',
    },
    hy: {
      name: 'Armenian',
      nativeName: 'Հայերեն',
      chineseName: '亚美尼亚语',
    },
    id: {
      name: 'Indonesian',
      nativeName: 'Indonesia',
      chineseName: '印尼语',
    },
    ig: {
      name: 'Igbo',
      nativeName: 'Ásụ̀sụ́ Ìgbò',
      chineseName: '伊博语',
    },
    ikt: {
      name: 'Inuinnaqtun',
      nativeName: 'Inuinnaqtun',
      chineseName: '伊努因纳克顿',
    },
    is: {
      name: 'Icelandic',
      nativeName: 'Íslenska',
      chineseName: '冰岛',
    },
    it: {
      name: 'Italian',
      nativeName: 'Italiano',
      chineseName: '意大利语',
    },
    iu: {
      name: 'Inuktitut',
      nativeName: 'ᐃᓄᒃᑎᑐᑦ',
      chineseName: '因纽特语',
    },
    'iu-Latn': {
      name: 'Inuktitut (Latin)',
      nativeName: 'Inuktitut (Latin)',
      chineseName: '因纽特语（拉丁语）',
    },
    ja: {
      name: 'Japanese',
      nativeName: '日本語',
      chineseName: '日语',
    },
    ka: {
      name: 'Georgian',
      nativeName: 'ქართული',
      chineseName: '格鲁吉亚语',
    },
    kk: {
      name: 'Kazakh',
      nativeName: 'Қазақ Тілі',
      chineseName: '哈萨克语',
    },
    km: {
      name: 'Khmer',
      nativeName: 'ខ្មែរ',
      chineseName: '高棉语',
    },
    kmr: {
      name: 'Kurdish (Northern)',
      nativeName: 'Kurdî (Bakur)',
      chineseName: '库尔德语（北部）',
    },
    kn: {
      name: 'Kannada',
      nativeName: 'ಕನ್ನಡ',
      chineseName: '卡纳达语',
    },
    ko: {
      name: 'Korean',
      nativeName: '한국어',
      chineseName: '韩语',
    },
    ku: {
      name: 'Kurdish (Central)',
      nativeName: 'Kurdî (Navîn)',
      chineseName: '库尔德语（中部）',
    },
    ky: {
      name: 'Kyrgyz',
      nativeName: 'Кыргызча',
      chineseName: '吉尔吉斯',
    },
    ln: {
      name: 'Lingala',
      nativeName: 'Lingála',
      chineseName: '林加拉语',
    },
    lo: {
      name: 'Lao',
      nativeName: 'ລາວ',
      chineseName: '老挝语',
    },
    lt: {
      name: 'Lithuanian',
      nativeName: 'Lietuvių',
      chineseName: '立陶宛语',
    },
    lug: {
      name: 'Ganda',
      nativeName: 'Ganda',
      chineseName: '干达',
    },
    lv: {
      name: 'Latvian',
      nativeName: 'Latviešu',
      chineseName: '拉脱维亚语',
    },
    lzh: {
      name: 'Chinese (Literary)',
      nativeName: '(文言文)',
      chineseName: '中文',
    },
    mai: {
      name: 'Maithili',
      nativeName: 'Maithili',
      chineseName: '迈蒂利',
    },
    mg: {
      name: 'Malagasy',
      nativeName: 'Malagasy',
      chineseName: '马尔加什',
    },
    mi: {
      name: 'Māori',
      nativeName: 'Te Reo Māori',
      chineseName: '毛利',
    },
    mk: {
      name: 'Macedonian',
      nativeName: 'Македонски',
      chineseName: '马其顿语',
    },
    ml: {
      name: 'Malayalam',
      nativeName: 'മലയാളം',
      chineseName: '马拉雅拉姆语',
    },
    'mn-Cyrl': {
      name: 'Mongolian (Cyrillic)',
      nativeName: 'Mongolian (Cyrillic)',
      chineseName: '蒙古语（西里尔字母）',
    },
    'mn-Mong': {
      name: 'Mongolian (Traditional)',
      nativeName: 'ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ',
      chineseName: '蒙古语（繁体）',
    },
    mr: {
      name: 'Marathi',
      nativeName: 'मराठी',
      chineseName: '马拉地语',
    },
    ms: {
      name: 'Malay',
      nativeName: 'Melayu',
      chineseName: '马来语',
    },
    mt: {
      name: 'Maltese',
      nativeName: 'Malti',
      chineseName: '马耳他语',
    },
    mww: {
      name: 'Hmong Daw',
      nativeName: 'Hmong Daw',
      chineseName: '苗族',
    },
    my: {
      name: 'Myanmar (Burmese)',
      nativeName: 'မြန်မာ',
      chineseName: '缅甸（缅语）',
    },
    nb: {
      name: 'Norwegian',
      nativeName: 'Norsk Bokmål',
      chineseName: '挪威',
    },
    ne: {
      name: 'Nepali',
      nativeName: 'नेपाली',
      chineseName: '尼泊尔语',
    },
    nl: {
      name: 'Dutch',
      nativeName: 'Nederlands',
      chineseName: '荷兰语',
    },
    nso: {
      name: 'Sesotho sa Leboa',
      nativeName: 'Sesotho sa Leboa',
      chineseName: '北索托',
    },
    nya: {
      name: 'Nyanja',
      nativeName: 'Nyanja',
      chineseName: 'Nyanja',
    },
    or: {
      name: 'Odia',
      nativeName: 'ଓଡ଼ିଆ',
      chineseName: '奥迪亚',
    },
    otq: {
      name: 'Querétaro Otomi',
      nativeName: 'Hñähñu',
      chineseName: '克雷塔罗奥托米',
    },
    pa: {
      name: 'Punjabi',
      nativeName: 'ਪੰਜਾਬੀ',
      chineseName: '旁遮普语',
    },
    pl: {
      name: 'Polish',
      nativeName: 'Polski',
      chineseName: '波兰语',
    },
    prs: {
      name: 'Dari',
      nativeName: 'دری',
      chineseName: '达里语',
    },
    ps: {
      name: 'Pashto',
      nativeName: 'پښتو',
      chineseName: '普什图语',
    },
    pt: {
      name: 'Portuguese (Brazil)',
      nativeName: 'Português (Brasil)',
      chineseName: '葡萄牙语（巴西）',
    },
    'pt-PT': {
      name: 'Portuguese (Portugal)',
      nativeName: 'Português (Portugal)',
      chineseName: '葡萄牙语（葡萄牙）',
    },
    ro: {
      name: 'Romanian',
      nativeName: 'Română',
      chineseName: '罗马尼亚语',
    },
    ru: {
      name: 'Russian',
      nativeName: 'Русский',
      chineseName: '俄语',
    },
    run: {
      name: 'Rundi',
      nativeName: 'Rundi',
      chineseName: 'Rundi',
    },
    rw: {
      name: 'Kinyarwanda',
      nativeName: 'Kinyarwanda',
      chineseName: '基尼亚卢旺达语',
    },
    sd: {
      name: 'Sindhi',
      nativeName: 'سنڌي',
      chineseName: '信德语',
    },
    si: {
      name: 'Sinhala',
      nativeName: 'සිංහල',
      chineseName: '僧伽罗语',
    },
    sk: {
      name: 'Slovak',
      nativeName: 'Slovenčina',
      chineseName: '斯洛伐克语',
    },
    sl: {
      name: 'Slovenian',
      nativeName: 'Slovenščina',
      chineseName: '斯洛文尼亚语',
    },
    sm: {
      name: 'Samoan',
      nativeName: 'Gagana Sāmoa',
      chineseName: '萨摩亚语',
    },
    sn: {
      name: 'Shona',
      nativeName: 'chiShona',
      chineseName: '绍纳语',
    },
    so: {
      name: 'Somali',
      nativeName: 'Soomaali',
      chineseName: '索马里语',
    },
    sq: {
      name: 'Albanian',
      nativeName: 'Shqip',
      chineseName: '阿尔巴尼亚语',
    },
    'sr-Cyrl': {
      name: 'Serbian (Cyrillic)',
      nativeName: 'Српски (ћирилица)',
      chineseName: '塞尔维亚语（西里尔文）',
    },
    'sr-Latn': {
      name: 'Serbian (Latin)',
      nativeName: 'Srpski (latinica)',
      chineseName: '塞尔维亚语（拉丁语）',
    },
    st: {
      name: 'Sesotho',
      nativeName: 'Sesotho',
      chineseName: '塞索托语',
    },
    sv: {
      name: 'Swedish',
      nativeName: 'Svenska',
      chineseName: '瑞典语',
    },
    sw: {
      name: 'Swahili',
      nativeName: 'Kiswahili',
      chineseName: '斯瓦希里语',
    },
    ta: {
      name: 'Tamil',
      nativeName: 'தமிழ்',
      chineseName: '梵语',
    },
    te: {
      name: 'Telugu',
      nativeName: 'తెలుగు',
      chineseName: '泰卢固语',
    },
    th: {
      name: 'Thai',
      nativeName: 'ไทย',
      chineseName: '泰语',
    },
    ti: {
      name: 'Tigrinya',
      nativeName: 'ትግር',
      chineseName: '提格里尼亚语',
    },
    tk: {
      name: 'Turkmen',
      nativeName: 'Türkmen Dili',
      chineseName: '土库曼语',
    },
    'tlh-Latn': {
      name: 'Klingon (Latin)',
      nativeName: 'Klingon (Latin)',
      chineseName: '克林贡语',
    },
    'tlh-Piqd': {
      name: 'Klingon (pIqaD)',
      nativeName: 'Klingon (pIqaD)',
      chineseName: '克林贡语 (pIqaD)',
    },
    tn: {
      name: 'Setswana',
      nativeName: 'Setswana',
      chineseName: '塞茨瓦纳语',
    },
    to: {
      name: 'Tongan',
      nativeName: 'Lea Fakatonga',
      chineseName: '汤加语',
    },
    tr: {
      name: 'Turkish',
      nativeName: 'Türkçe',
      chineseName: '土耳其语',
    },
    tt: {
      name: 'Tatar',
      nativeName: 'Татар',
      chineseName: '鞑靼语',
    },
    ty: {
      name: 'Tahitian',
      nativeName: 'Reo Tahiti',
      chineseName: '塔希提语',
    },
    ug: {
      name: 'Uyghur',
      nativeName: 'ئۇيغۇرچە',
      chineseName: '维吾尔语',
    },
    uk: {
      name: 'Ukrainian',
      nativeName: 'Українська',
      chineseName: '乌克兰语',
    },
    ur: {
      name: 'Urdu',
      nativeName: 'اردو',
      chineseName: '乌尔都语',
    },
    uz: {
      name: 'Uzbek (Latin)',
      nativeName: 'Uzbek (Latin)',
      chineseName: '乌兹别克语（拉丁语）',
    },
    vi: {
      name: 'Vietnamese',
      nativeName: 'Tiếng Việt',
      chineseName: '越南语',
    },
    xh: {
      name: 'Xhosa',
      nativeName: 'isiXhosa',
      chineseName: '科萨语',
    },
    yo: {
      name: 'Yoruba',
      nativeName: 'Èdè Yorùbá',
      chineseName: '伊博语',
    },
    yua: {
      name: 'Yucatec Maya',
      nativeName: 'Yucatec Maya',
      chineseName: '尤卡坦玛雅语',
    },
    yue: {
      name: 'Cantonese (Traditional)',
      nativeName: '繁體粵語',
      chineseName: '粤语（繁体）',
    },
    'zh-Hans': {
      name: 'Chinese Simplified',
      nativeName: '简体中文',
    },
    'zh-Hant': {
      name: 'Chinese Traditional',
      nativeName: '繁體中文',
    },
    zu: {
      name: 'Zulu',
      nativeName: 'Isi-Zulu',
      chineseName: '祖鲁语',
    },
  }
  export const MOUSE_HOVER_TRANSLATE_ACTION_LIST: Array<{
    action: TranslateServiceType.MouseHoverTranslateAction
    displayName: string
  }> = [
    {
      action: 'translate-with-ctrl',
      displayName: '悬停 + Ctrl 翻译',
    },
    {
      action: 'translate-with-shift',
      displayName: '悬停 + Shift 翻译',
    },
    {
      action: 'translate-with-alt',
      displayName: '悬停 + Alt/Opt 翻译',
    },
    {
      action: 'translate-with-meta',
      displayName: '悬停 + Win/Cmd 翻译',
    },
    {
      action: 'direct-translate',
      displayName: '悬停 直接翻译',
    },
  ]

  export const SCRIBE_TRANSLATE_ACTION_LIST: Array<{
    action: TranslateServiceType.ScribeTranslateType
    displayName: string
  }> = [
    {
      action: 'hover',
      displayName: '图标悬停翻译',
    },
    {
      action: 'click',
      displayName: '图标点击翻译',
    },
  ]
}
